@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.alert-success{
  z-index: 9;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.alert {
    position: fixed !important;
    right: 30px;
    bottom: 20px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
}

.alert-danger{
  z-index: 100000000000 !important;
}
body {
  font-family: "Montserrat", sans-serif;
  font-weight: bold !important;
}

.flt {
  float: left;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

/* Location Details start*/

/* company_details */

.company_hedding {
  background-color: #003f7d;
  display: flex;
  text-align: center;
  padding: 2px 16px;
  margin: 4px 0px;
}

.company_icon {
  display: flex;
  align-items: center;
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 8px 4px;
}

.company_icon img {
  padding: 2px 6px;
}

.edit_details {
  text-align: right;
}

.edit_details1 {
  padding: 30px 0px;
}

.edit_button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 11px 24px;
  font-size: 12px;
  background: #ffffff;
}

.edit_button1 {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 8px 24px;
  font-size: 12px;
  background: #edf6ff;
  border-radius: 2px;
  border: 1px solid #003f7d;
  box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
  display: flex;
  align-items: center;
}

.edit_button1 i {
  color: #ff8e01;
  padding: 0px 4px;
}

.edit_button i {
  color: #ff8e01;
  padding: 0px 4px;
}

.investor_mainbb2 .fliter_button2 {
  width: auto;
}

.investor_search_dd .search_l {
  width: 50%;
}

.Company_name {
  background: #c7e2f9;
  display: flex;
  align-items: center;
  text-align: right;
  padding: 2px 0px;
}

.comp_name_details {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #003466;
  padding: 9px 10px;
}

.comp_name_details img {
  padding: 0px 4px;
}

.E_comp_details {
  padding: 6px;
}

.main_company {
  width: 100%;
  /* box-shadow: 0px 0px 8px rgb(0 12 116 / 20%); */
  margin: 7px 0px;
  padding-bottom: 40px;
}

.Company_name_week {
  background: #c7e2f9;
  padding: 2px 0px;
}

/* .insuranceinfo_show{
  pointer-events: none;
  opacity: 0.5;
} */

/* company button start */
.user_btn {
  text-align: right;
}

.add_btn {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 11px 24px;
  font-size: 12px;
  margin: 0px 3px;
  background-color: #ffffff;
}

.add_btn:hover {
  background: #fd9a03;
}

.add_btn i {
  padding-right: 4px;
  /* color: #fd9a03; */
}

.add_btn i:hover {
  color: #003466;
}

/* company button end */

.main_monthly {
  margin: 7px 0px;
}

.form-group {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

.labelName {
  padding: 6px 4px;
}

.form-group .input {
  border: none;
  background: #f5f5f5;
  padding: 12px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
}

.form-group .input::-webkit-input-placeholder {
  font-weight: 600;
}

.form-group .input::placeholder {
  font-weight: 600;
}

.make_img {
  display: flex;
  align-items: center;
}

.make_img12 {
  display: flex;
  align-items: center;
}

.make_img12 img {
  padding: 5px 6px;
  background-color: #f5f5f5;
  margin-right: 2px;
}

.make_img i {
  padding: 11px 9px;
  align-items: center;
  margin-right: 2px;
  display: flex;
  background-color: #f5f5f5;
  font-size: 12px;
}

i.fas.fa-trash-alt {
  color: rgb(206, 26, 62);
}

.make_img button {
  /* padding: 4px 6px; */
  margin-left: 2px;
  border-radius: 2px;
  border: none;
  /* background: #C7E2F9; */
}

.company_name textarea {
  border: none;
  background: #f5f5f5;
  padding: 2px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
}

.company_name input {
  border: none;
  background: #f5f5f5;
  padding: 10px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  outline: none;
}

.company_name select {
  border: none;
  background: #f5f5f5;
  padding: 10px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  outline: none;
}

.company_main_name {
  background-color: #003f7d;
  display: flex;
  text-align: center;
  margin: 4px 0px;
}

.company_name1 {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 6px 0px;
}

.company_name1 img {
  padding: 6px 6px;
}

.upload_img_btn button {
  width: 100%;
  height: 165px;
  background-color: rgba(248, 248, 248, 1);
  border: 2px dotted rgba(118, 118, 118, 1);
  border-radius: 5px;
}

.upload_img_btn {
  position: relative;
  cursor: pointer;
}

.upload_img_btn input {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.upload_img {
  padding: 16px 18px;
}

.browse_logo_img {
  padding: 6px 0px;
  text-decoration: underline;
  color: #003f7d;
}

.main_ownbutton {
  margin: 22px 0px;
}

.own_button {
  text-align: right;
}

.own_button button {
  border: 1px solid #003f7d;
  padding: 12px 25px;
  /* width:44%; */
  margin: 0px 8px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
}

.own_button button:hover {
  background-color: #003466;
  color: #ffffff;
}

/* Location Details end */

/* filter button start */

.main_location {
  background: #003f7d;
  display: flex;
  text-align: center;
  margin: 4px 0px;
}

.location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 11px 14px;
  display: flex;
  align-items: center;
}

.location img {
  padding: 0px 6px;
}

.fliter_button {
  text-align: right;
  padding: 3px 15px;
}

.fliter_button button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 18px;
  font-size: 12px;
  margin: 0px 2px;
  background: #ffffff;
  border-radius: 2px;
}

.fliter_button i {
  padding: 0px 8px;
  color: #ff8e01;
}

.fliter_button2 i {
  padding: 0px 8px;
  color: #ff8e01;
}

.fliter_button2 button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 16px;
  font-size: 12px;
  margin: 0px 2px;
  background: #ffffff;
  border-radius: 2px;
  width: 100%;
}

/* filter button end */

/* box Shadow start */
/* .main_emission {
   box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2); 
    margin: 6px 0px;
} */

.main_Submit_btn {
  padding: 20px 0px;
}

/* box Shadow end  */

/* day to day task start */

.delete_b {
  display: flex;
  padding: 3px 14px;
  align-items: center;
  justify-content: flex-end;
}

.delete_btn {
  border: none;
  padding: 8px 20px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #ff8e01;
}

.delete_btn12 {
  width: 20%;
  border: none;
  padding: 10px 0px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #003f7d;
}

.main_boxShadow {
  box-shadow: 0px 0px 8px rgb(0 12 116 / 20%);
}

.main_search_b {
  display: flex;
  align-items: center;
}

.search_b {
  width: 100%;
  padding: 5px 10px;
  display: flex;
}

.search_b input {
  width: 100%;
  border: none;
  background: #c7e2f9;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 10px;
  color: #003466;
}

.search_button {
  background: #ffffff;
  border: none;
  padding: 4px 8px;
}

.main_box_shadow {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.main_general {
  padding: 0px 22px;
}

.main_vehicle_info {
  background-color: #003f7d;
  margin-top: 6px;
  padding: 1px 0px;
}

.vehicle_info {
  margin: 0px;
  padding: 0px;
}

.vehicle_info li {
  float: left;
  list-style-type: none;
  display: inline;
  padding: 10px 0px;
  margin: 0px 2px;
}

/* .vehicle_info li:hover {
 background-color: #FFFFFF;

} */
.vehicle_info li a:hover {
  color: #003f7d;
}

.vehicle_info li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  padding: 16px 15px;
}

.general_informaion {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.insurance_info {
  margin: 0px;
  padding: 0px;
}

.insurance_info li {
  float: left;
  list-style-type: none;
  display: inline;
  padding: 6px 0px;
  border: 2px solid #f7f7f7;
}

.insurance_info li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #003f7d;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  font-weight: 600;
  padding: 13px 16px;
}

.insurance_info li:hover {
  border-bottom: 2px solid #003f7d;
}

.insurance_infos {
  border-bottom: 2px solid #003f7d !important;
}

/* day to day task end */
/* pagination start*/

.pagination {
  display: flex;
  float: right;
  padding: 5px 5px !important;
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 10px;
}

.page-item.active .page-link {
  background-color: #003f7d !important;
}

/* .pagination a {
  background-color: #231976 !important ;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 11px;
}

.pagination a.active {
  background-color: #003f7d !important;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
} */

/* pagination end */

/* table css start */

.main_table1 {
  border-collapse: collapse;
  position: relative;
}

.main_table1 .sc-evZas {
  background: #c7e2f9;
  color: #003466 !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.main_table1 .GlssT {
  font-size: 10px;
}

.main_tablehead {
  overflow-x: auto;
  position: relative;
  min-height: 300px;
}

.main_tableheadnew {
  overflow-x: auto;
  position: relative;
  min-height: 300px;
}

.table_head1 {
  background: #c7e2f9;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.table_head1 th {
  padding: 10px 10px;
  /* text-align: left; */
}

.table_row1:nth-child(even) {
  background: #f5f5f5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.table_row1 td {
  /* text-align: left; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding: 14px 10px;
  color: #003f7d;
}

.table_row1 .select_btn {
  border-radius: 2px;
  padding: 8px 25px;
  background: #ebf6ff;
  border: 1px solid #c7e2f9;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
}

.table_row1 .select_btn2 {
  padding: 9px 25px;
  border: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background: #d0ffc5;
  color: #00610a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
}

.table_row1 .select_btn3 {
  padding: 9px 12px;
  border: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background: #ebf6ff;
  color: #a30000;
  background: #ffc4c4;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
}

/* table css end */

/* company start */
.chat_right1 {
  padding-left: 2px;
  margin: 4px 0px;
  width: calc(100% - 233px);
}

/* company end */

/* calender button  */
.dgRweL {
  width: 26px !important;
  height: 26px !important;
  position: absolute;
  cursor: pointer;
  bottom: 3px;
  margin: auto;
  right: 10px;
}

/* calender button */

.daily_basis {
  padding: 10px 0px;
}

.main_search_dd {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.search_l {
  width: 40%;
}

.search_b1 {
  width: 60%;
  display: flex;
}

.search_b1 input {
  width: 100%;
  border: none;
  background: #c7e2f9;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 10px;
  color: #003466;
  outline: none;
}

.search_l select {
  width: 100%;
  padding: 10px 0px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
}

.fliter_button2 {
  text-align: right;
  padding: 3px 20px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hestory {
  padding: 0px 6px;
  display: flex;
  align-items: center;
  color: #ffff;
}

.main_booking_info {
  background-color: #003f7d;
  padding: 15px 0px;
}

.booking_vehicle {
  text-align: left;
  padding-left: 12px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.main_ownbutton.flt {
  display: flex;
  align-items: center;
}

.own_button {
  text-align: right;
  /* / float: right; / */
}

.label_block {
  border: 1px solid #ffc3c3;
  border-radius: 4px;
  color: #003f7d;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
}

.add_cls {
  display: flex;
  align-items: center;
  padding: 0px 6px;
}

.add_cls input {
  display: flex;
  align-items: center;
  margin: 0px 6px;
}

.main_booking_info1 {
  background-color: #003f7d;
  padding: 15px 0px;
  display: flex;
  align-items: center;
}

.main_bloack {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.label_block img {
  background: #ffc3c3;
  border-radius: 4px;
  padding: 10px 10px;
}

.label_block.input {
  padding: 2px 2px;
}

/* set up vehicle start*/
.E_comp_deta.flt {
  padding: 0px 3px;
}

.main_row.flt {
  padding: 0px 10px;
}

.main_tab_.flt {
  padding: 30px 8px;
}

/* set up vehicle end*/

/* upload button start */

.up_button img {
  cursor: pointer;
}

.up_button input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.jpg_link.flt {
  display: flex;
  justify-content: space-between;
}

.frontside_img.flt {
  border: 2px dotted rgba(118, 118, 118, 1);
  border-radius: 5px;
  width: 100%;
  height: 225px;
}

.main_uploded.flt {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}

.upload_delete {
  border: none;
  margin: 0px 5px;
  width: 55px;
  background: #003f7d;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 7px 0px;
  border: 1px solid transparent;
}

.upload_delete:hover {
  background-color: #f7f7f7;
  color: #003f7d;
  border: 1px solid #003f7d;
}

.uploded.flt {
  display: flex;
  align-items: center;
}

.exit_button {
  display: flex;
  align-items: center;

  justify-content: right;
}

.updated {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #00732e;
  padding: 0px 5px;
}

.upload_padding {
  padding: 16px 5px;
}

.uploded {
  display: flex;
  align-items: center;
}

.pan_image img {
  width: 100%;
}

.updated1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 0px 5px;
  color: #a30000;
}

/* upload image 2 end*/
.close_button {
  border: none;
  padding: 2px 15px;
  margin: 0px 5px;
}

.download_button {
  padding: 7px 10px;
}

/* .up_button1 {
  position: relative;
  padding: 10px 8px;
} */

.abc {
  padding: 4px 4px;
}

.up_button {
  position: relative;
  padding: 9px 10px;
  cursor: pointer;
}

.download_button {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  margin: 0px 2px;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 5px 0px;
}

.download_button i {
  padding: 0px 5px;
}

.modal {
  background: #000000ba;
}

.labelName2 {
  padding: 6px 0px !important;
  width: 50%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

.labelName3 {
  padding: 6px 0px !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

/* pop up start */
.vehicle_due img {
  width: 100% !important;
}

/* .react-pdf__Page__canvas {
    width: 100% !important;
} */

/* .pagec {
  font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
} */

/* .buttonc button{
  background: #003F7D;
  color: #FFFFFF;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  margin: 0px 2px;
  padding:10px 10px;
} */

/* pop up end */
/* upload button end */

/* .furIyG {
  z-index: 1 !important;
} */

.cal_der {
  display: flex;
  align-items: center;
  padding: 0px 4px;
  width: 50%;
  margin: 0px 7px;
}

/* / data table css  / */
.rdt_TableRow:nth-child(even) {
  background: #f5f5f5;
}

/* / data table css  / */

/* coomingsoon start */

.cooming_soon1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  color: #003f7d;
  text-align: center;
}

.classOne {
  min-height: calc(100vh - 116px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* coomingsoon end */

/* onrent start */
.main_search_b1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 2px;
}

.main_search_dd1 {
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0px 10px;
}

/* onrent end */

/* download_button start */
.main_downld {
  text-align: center;
  padding: 8px 10px;
}

.main_downld a {
  text-decoration: none;
  padding: 10px 20px;
  color: #f7f7f7;
  background-color: #003466;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.main_payment.flt {
  /* padding-top: 20px;
   */
  padding: 0px 10px;
}

.main_headbox.flt {
  padding: 10px 12px;
}

.main_grand_total.flt {
  padding: -8px;
  padding: 0px 10px;
}

/* download_button end */

/* upload image 2 start*/
.upload_img_btn1 button {
  width: 100%;
  height: 225px;
  background-color: rgba(248, 248, 248, 1);
  border: 2px dotted rgba(118, 118, 118, 1);
  border-radius: 5px;
}

.upload_img_btn1 {
  position: relative;
  cursor: pointer;
}

.upload_img_btn1 input {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.upload_img1 {
  padding: 16px 5px;
}

.main_pay_debit1.flt {
  background: #c7e2f9;
  border: 1px solid #e0f0ff;
}

/* upload Image 2 end */

/* start issue start */

.gap_search.flt {
  display: flex;
}

/* start issue end  */

.eQmQXs {
  z-index: 9999;
}

img.cal {
  position: relative;
  left: 246px;
  /* right: 0px; */
  /* top: -18px; */
  bottom: 30px;
}

.new_location9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0px 0px;
}

.qlook_view {
  margin-top: 5px;
  margin-bottom: 30px;
}

.qlookup_table thead tr {
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat";
  background: #c7e2f9;
  color: #003466 !important;
}

.qlookup_table thead tr th {
  padding: 15px !important;
}

.qlookup_table tbody tr {
  background-color: rgb(255, 255, 255);
}

.qlookup_table tbody tr:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.qlookup_table tbody tr td {
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  padding: 15px !important;
}

.qlookbtn {
  margin-left: 5px;
}

.main_table9 {
  padding: 5px;
}

.main_table9 table {
  width: 100%;
}

/* Media Query */

@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .vehicle_info li a {
    padding: 0px !important;
  }

  .main_search_dd {
    display: block;
  }

  /* @media (max-width: 767px) { */
  .hide_mobile {
    display: none;
  }

  .ex_cust {
    width: 100% !important;
  }

  .search_b11 {
    width: 100%;
    padding: 2px 10px;
  }

  .total_amt {
    width: 100% !important;
    margin-top: 6px;
  }

  /* user */

  .top_cont {
    display: flex;
    justify-content: space-between;
  }

  .agree_list li {
    padding: 6px 6px;
  }

  .menu_h li {
    width: 33% !important;
    min-height: 72px;
  }

  .chat_right {
    width: 100% !important;
  }

  .chat_right1 {
    width: 100% !important;
  }

  .main_userControl {
    display: block !important;
    height: auto !important;
    padding: 15px 15px !important;
  }

  .user_btn {
    text-align: left !important;
    margin-top: 10px;
  }

  .add_btn {
    width: 30% !important;
    padding: 10px 4px !important;
    font-size: 12px;
    margin-right: 2%;
  }

  .main_tablehead {
    overflow-x: auto;
  }

  .main_tableheadnew {
    overflow-x: auto;
  }

  .fliter_button button {
    width: 32%;
    padding: 12px 0px;
    margin: 2px 2px;
  }

  .main_location {
    display: block;
  }

  .fliter_button {
    display: block;
    padding: 2px 2px;
    text-align: center;
  }

  .own_button {
    text-align: center !important;
    padding: 25px 10px;
  }

  .edit_details {
    text-align: right;
    margin: 0px 2px !important;
  }

  .main_search_b {
    display: inline;
    padding: 4px 0px;
  }

  .insurance_info li {
    padding: 6px 4px;
    width: 100%;
  }

  .own_button button {
    width: 40%;
  }

  .mobile_pass.flt {
    padding: 15px 0px;
  }

  .main_search_b2 {
    display: inline-block !important;
  }

  .main_search_dd {
    padding: 5px 10px;
  }

  .fliter_button2 {
    justify-content: start !important;
    padding: 3px 0px !important;
    margin: 0px 7px;
  }

  .main_search_dd1 {
    width: 100% !important;
  }

  .delete_btn123 {
    width: 100% !important;
  }

  .delete_btn12 {
    width: 100% !important;
  }

  .fliter_button2 button {
    padding: 10px 0px !important;
    width: 77% !important;
  }

  .main_general {
    padding: 0px 10px !important;
  }

  .E_comp_details {
    padding: 6px 10px !important;
  }

  .l_der {
    padding: 0px 9px !important;
    width: 100% !important;
  }

  img.cal {
    left: 295px;
  }

  /* .main_search_dd {
    display: inline-block !important;
  }  */

  .button_style_onrent {
    padding: 6px 16px !important;
  }

  .button_style_available {
    padding: 6px 15px !important;
  }

  .main_search_b1 {
    padding: 4px 0px !important;
  }

  .upload_img {
    padding: 5px 18px !important;
  }

  .viewdata {
    padding: 6px 7px !important;
    width: 100%;
  }

  .phone_btn {
    padding: 36px 6px !important;
  }

  .Phone_class {
    width: 40% !important;
  }

  .phone_input {
    padding: 0 17px !important;
    width: 40% !important;
  }

  .serach_button {
    width: 30% !important;
  }

  .edit_vehicle_fix {
    display: flex;
  }
}

@media (min-width: 768px) {
  .hide_desktop {
    display: none;
  }
}

.modal1 {
  opacity: 1 !important;
  position: fixed;
  z-index: 999;
  display: block;
}

.book_st {
  display: flex;
  text-align: center;
  padding: 0px 45px;
}

.pop_car.flt {
  display: flex;
  justify-content: center;
}

/* agreement */
.otp_back {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  width: 50%;
  border: none;
  height: 40px;
  margin: 0px 10px;
  border-radius: 3px;
}

.otp_button {
  display: flex;
  align-items: center;
  margin: 40px 0px;
}

.otp_back:hover {
  background: #ffffff;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003466;
}

.otp_code.flt {
  display: flex;
  justify-content: space-between;
}

.otp_code input {
  margin: 0px 5px;
  text-align: center;
  color: #003466;
}

.verify_msg.flt {
  margin: 12px 0px;
  background: #cae5ff;
  padding: 10px 10px;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #003f7d;
}

.success_img img {
  margin: 0px 5px;
}

.verify_msg1.flt {
  margin: 12px 0px;
  background: #fff8f8;
  padding: 10px 10px;
  color: #f00000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #f33e3e;
}

.resend_otp.flt {
  padding: 10px 12px;
}

.re_button {
  width: 100%;
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  border-radius: 2px;
  padding: 15px 0px;
}

.mob_number {
  padding: 12px 14px;
  align-items: center;
  margin-right: 2px;
  display: flex;
  background-color: #f5f5f5;
}

.main_vehicle_drop select {
  padding: 8px 5px;
  border: none;
  color: #003466;
  background-color: #ffffff;
  outline: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

/* extra css */
.agedata.flt {
  padding: 35px 6px;
}

select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__amPm {
  height: auto !important;
  width: auto !important;
}

.react-datetime-picker__inputGroup__input {
  height: auto !important;
}

.main_car_padding.flt {
  padding: 30px 10px;
}

.GlssT:not(:last-of-type) {
  border: none !important;
}

.req_field {
  padding: 3px 3px;
  color: #b42b2b;
}

/* / Car start / */
.main_car_padding.flt {
  padding: 30px 10px;
}

.make_details li {
  float: left;
  list-style-type: none;
  width: 50%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 16px;
}

.details_car {
  background: #f5f5f5;
  padding: 20px 15px;
  text-align: left;
  display: flex;
}

.But_padding {
  padding: 55px 0px;
}

.own_button1 button {
  border: 1px solid #003f7d;
  padding: 12px 50px;
  /* / width:100%; / */
  margin: 0px 8px;
  color: #f7f7f7;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background-color: #003466;
  box-sizing: border-box;
  border-radius: 2px;
}

/* / Car End / */

/* / New payment start  / */
.paid_class.flt {
  padding: 0px;
  margin: 0px;
  float: left;
}

.paid_class li {
  float: left;
  list-style-type: none;
  width: 70%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 10px;
}

.main_paid_class.flt {
  background: #c7e2f9;
  padding: 20px 0px;
  border: 1px solid #e0f0ff;
}

.grand_ttl.flt {
  padding-bottom: 10px;
  padding-right: 10px;
}

.paid_class li span {
  float: right;
}

li.pay_lst {
  width: 30%;
}

/* / New payment end / */

.main_booking_info12 {
  background-color: #003f7d;
  padding: 5px 0px;
  display: flex;
  align-items: center;
}

.grand_ttl1.flt {
  /* padding-bottom: 10px; */
  /* padding-right: 10px; */
  padding: 5px 10px;
}

.ownbutton1 {
  padding-top: 10px;
}

/* .bMgaAx div:first-child {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
} */

.rdt {
  width: 100%;
}

/* .rdt_TableRow .sc-hKMtZM:nth-child(1) {
    max-width: 70px;
}

.rdt_TableHeadRow .jzGaAN:nth-child(1) {
    max-width: 70px !important;
} */
.cGZmld {
  max-width: auto !important;
  min-width: auto !important;
}

.GlssT {
  color: rgb(0 0 0) !important;
}

.cal_img {
  position: absolute;
  right: 2%;
  top: 13%;
  bottom: 0%;
}

.main_cal_img {
  position: relative;
}

.main_search_dd12 {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.main_load img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.main_load {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
}

.rent_walk {
  padding: 15px !important;
}

.table_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.table_loader img {
  width: 100%;
  height: 256px;
}

.no-data {
  text-align: center;
  padding: 10% 0;
  width: 100%;
}

.main_boxShadowouter {
  box-shadow: 0px 0px 8px rgb(0 12 116 / 20%);
  position: relative;
  min-height: 300px !important;
}

.table_head2 {
  background: #ffffff;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.table_head2 th {
  padding: 10px 10px;
  /* text-align: left; */
}

.looparea {
  margin-bottom: 0px;
  padding-right: 2rem;
}

.divider {
  border-bottom: 1px dashed #003466;
  margin: 20px 0px;
}

.search_main7 {
  width: 30%;
  margin-left: 5px !important;
}

.search_main2 {
  margin-left: 5px !important;
}

.search_date {
  margin-left: 5px;
  width: 40%;
}

.search_date input {
  font-size: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  border-radius: 0px;
  border: none;
  width: 100%;
  padding: 10px 5px;
}

/* .search_date .rdtPicker {
  left: -125px;
} */

.checkout_search9 {
  padding: 6px 15px;
}

.search_l22 {
  width: 30%;
}

.qlookphbtn .search_date input {
  font-size: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0px;
  width: 100%;
  padding: 10px 5px;
}

.qlookphbtn .search_date .rdtPicker {
  left: 0px;
}

.qlookphbtn .search_daterh input {
  font-size: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0px;
  width: 100%;
  padding: 10px 5px;
}

.qlookphbtn .search_daterh .rdtPicker {
  left: 0px;
}

.search_daterh {
  margin-left: 5px;
  width: 40%;
}

.checkout_search9 .main_search_dd12 .form-group {
  padding: 6px 0px;
  margin-right: 5px;
}

.checkout_search9 .main_search_dd12 .form-group:last-child {
  margin-right: 0px;
}

.main_search_dd12new {
  padding: 0px 5px;
}

.main_search_dd12new .eQmQXs {
  right: 0;
}

.search_date .form-group {
  padding: 6px 0px;
}

.search_date .eQmQXs {
  right: 0px;
}

.vehicles_mainsearch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 15px;
}

.vehicle_search_dd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  padding: 0px 5px;
}

.vehicle_search_dd .search_l {
  width: 50% !important;
}

.vehicle_search_dd .search_b1 {
  width: 50% !important;
}

.vehicle_filterbtn {
  padding: 0px !important;
  width: auto;
  text-align: left;
}

.vehicle_filterbtn button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 16px;
  font-size: 12px;
  margin-left: 5px;
  background: #ffffff;
  border-radius: 2px;
}

.vehicle_filterbtn button i {
  padding: 0px 8px;
  color: #ff8e01;
}

.vehicle_filterbtn.fliter_button2 button {
  margin: 0px;
}

.vehicles_mainsearch .search_l select {
  padding: 11px;
}

.vehicles_mainsearch .search_b1 input {
  padding: 11px;
}

.vehicle_geninfo .eQmQXs {
  right: 0px;
}

.vehicle_document .eQmQXs {
  left: 0px;
}

.vehicle_docexpiry .eQmQXs {
  left: 0px;
}

.vehicle_docrcexpiry .eQmQXs {
  right: 0px;
}

.vehicle_maintanance .eQmQXs {
  left: 0px;
}

.vehicle_ntservice .eQmQXs {
  right: 0px;
}

.vehicle_oilservice .eQmQXs {
  left: 0px;
}

.vehicle_damage .eQmQXs {
  right: 0px;
}

.vehicle_rentdamage .eQmQXs {
  left: 0px;
}

.vehicle_sold .eQmQXs {
  right: 0px;
}

.baddpersonal .rdtPicker {
  left: 0px;
}

.bookenddate .rdtPicker {
  right: 0px;
}

.booking_searchmain {
  display: flex;
  align-items: center;
  padding: 6px 10px;
}

.booking_searchmain .ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.booking_search_dd {
  width: auto;
  padding: 0px 5px;
}

.booking_filterbtn {
  padding: 0px !important;
  width: auto;
}

.booking_search_dd .search_l select {
  padding: 11px 0px;
}

.booking_search_dd .search_b1 input {
  padding: 11px 0px;
}

.booking_startrent {
  display: flex !important;
  align-items: center !important;
  padding: 6px 10px;
}

.booking_startrent .ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.bkstartrent_dd {
  width: auto;
  padding: 0px 5px;
}

.bkstartrent_dd .search_l select {
  padding: 11px 0px;
}

.bkstartrent_dd .search_b1 input {
  padding: 11px 0px;
}

.bkstartrent_btn {
  padding: 0px !important;
  width: auto;
}

.daytoday_main {
  padding: 5px 15px;
}

.daytoday_main .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0px !important;
}

.daytoday_search_dd {
  width: auto;
  padding: 0px 5px;
}

.daytoday_search_dd .search_l select {
  padding: 11px 0px;
}

.daytoday_search_dd .search_b1 input {
  padding: 11px 0px;
}

.daytoday_btn {
  padding: 0px !important;
  width: auto;
}

.daytoday_btn button {
  margin: 0px;
}

.closed_search_b22 {
  padding: 6px 20px;
}

.closed_search_b22 .main_search_dd12 {
  padding: 0px;
}

.closed_search_b22 .form-group {
  padding: 0px 5px 0px 0px;
}

.closed_search_b22 .search_l select {
  padding: 11px 0px;
}

.closed_search_b22 .search_b1 input {
  padding: 11px 0px;
}

.closed_search_b22 .wfIwh {
  padding: 11px 0px !important;
}

.customer_listb33 {
  display: flex;
  align-items: center;
  padding: 6px 15px;
}

.customer_listb33 .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.customer_listb33 .location img {
  padding: 0px;
  margin-right: 6px;
}

.clist_search_dd {
  width: auto;
  padding: 0px 5px;
}

.clist_search_dd .search_l select {
  padding: 11px 0px;
}

.clist_search_dd .search_b1 input {
  padding: 11px 0px;
}

.clist_btn {
  padding: 0px !important;
  width: auto;
}

.clist_btn button {
  margin: 0px;
}

.investor_mainbb2 {
  display: flex;
  align-items: center;
  padding: 6px 15px;
  justify-content: flex-end !important;
}

.investor_mainbb2 .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.investor_mainbb2 .location img {
  padding: 0px;
  margin-right: 6px;
}

.investor_search_dd {
  width: auto;
  padding: 0px 5px;
}

.investor_search_dd .search_l select {
  padding: 11px 0px;
}

.investor_search_dd .search_b1 input {
  padding: 11px 0px;
}

.investor_btnb22 {
  padding: 0px !important;
}

.investor_btnb22 button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 16px;
  font-size: 12px;
  margin-left: 5px;
  background: #ffffff;
  border-radius: 2px;
}

.investor_btnb22 i {
  padding: 0px 8px;
  color: #ff8e01;
}

.qlookphbtn .search_l select {
  width: 100%;
  padding: 10px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ced4da;
}

.adeditlocatin {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.booking_newb44 {
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.booking_newb44 .ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.booking_searchn44 {
  width: auto;
  padding: 0px 5px;
}

.booking_searchn44.search_l select {
  padding: 11px 0px;
}

.bookingflbtn {
  padding: 0px !important;
  width: auto;
}

.bookingflbtn button {
  margin: 0px;
}

.rental_mainpay {
  display: flex !important;
  align-items: center !important;
  padding: 5px 15px !important;
}

.rental_mainpay .main_search_dd1 {
  padding: 0px !important;
  justify-content: flex-end;
}

.rentex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  padding: 0px;
  width: auto !important;
}

.rentalpayment .form-group {
  padding: 0px 5px 0px 0px !important;
}

/* .rentalpayment .form-group input {
  padding: 11px 0px !important;
  height: 44px;
} */

.rentalpayment .total_amt {
  width: auto !important;
}

.payment_rlist_tabs .main_vehicle {
  padding: 5px;
}

.payment_rlist_tabs .vehicle_info1 .menuActive {
  border: none !important;
}

.ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.reprental_srchdd77 {
  padding: 0px !important;
}

.reprental_srchdd77 .form-group {
  padding: 0px 5px 0px 0px !important;
}

.reptrental {
  padding: 5px 15px !important;
}

.pendpay_main {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end;
}

.pendpay_main .form-group {
  width: 50% !important;
  position: relative;
}

.pendpay_main .cal_img {
  position: absolute;
  top: 12px;
  right: 12px;
}


.chsearch_tt .search_l {
  width: 90%;
}

.vehic_expiryhh .form-group {
  width: 70% !important;
  position: relative;
}

.vehic_expiryhh .form-group .cal_img {
  position: absolute;
  top: 12px;
  right: 12px;
}

.vehic_expiryhh .eQmQXs {
  right: 0px;
}

.booking_srchmm {
  padding-right: 5px;
  width: 15%;
}

.booking_srchmm input {
  padding: 11px 10px;
}

.closed_search_b22 .search_l {
  padding-right: 5px;
  width: 100%;
}

.closed_search_b33 .search_l {
  padding-right: 5px;
  width: 30%;
}

.closed_search_b33 .search_b1 {
  width: 30%;
}

.closed_search_b33 .form-group {
  width: 30%;
}

.checkout_search9 .search_l22 {
  width: 30%;
}

.checkout_search9 .search_b1 {
  width: 30%;
}

.checkout_search9 .search_main7 {
  width: 30%;
}

.checkout_search91 {
  padding: 6px 15px;
}

.checkout_search91 .search_l22 {
  width: 30%;
}

.checkout_search91 .search_b1 {
  width: 30%;
}

.checkout_search91 .search_main7 {
  width: 30%;
}

.checkout_search91 .form-group {
  width: 30%;
  padding: 0px;
  margin-left: 5px;
  position: relative;
}

.checkout_search9 .form-group {
  padding: 0px;
}

.vehicles_mainsearch .search_l {
  width: 30%;
}

.vehicles_mainsearch .search_b1 {
  width: 30%;
}

.vehiclelist_v777 {
  display: flex !important;
  align-items: center !important;
  padding: 6px 15px;
}

.vehiclelist_v777 .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.vhsearch_l {
  width: auto;
}

.vhsearch_l select {
  padding: 11px;
}

.vhsearch_b2 {
  width: auto;
}

.vhsearch_b2 input {
  padding: 11px 0px;
}

.invest_search_l select {
  width: 100%;
  padding: 10px 0px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
}

.checkout_investor .fliter_button2 {
  display: block !important;
}

.checkout_investor {
  padding: 6px 15px;
}

.checkout_investor .search_l22 {
  width: 30%;
}

.checkout_investor .search_b1 {
  width: 30%;
}

.checkout_investor .search_main7 {
  width: 30%;
}

.checkout_investor .form-group {
  width: 30%;
  padding: 0px;
  margin-left: 5px;
}

.checkout_investor select {
  padding: 11px 0px;
}

.checkout_investor input {
  padding: 11px 0px;
}

.company_name .form-group {
  position: relative;
}

.cal_img {
  position: absolute;
  right: 2%;
  top: 50%;
}

.vehicle_docexpiry .make_img {
  position: relative !important;
}

.vehicle_docexpiry .make_img .cal_img {
  position: absolute !important;
  right: 85px !important;
  top: 5px !important;
}

.vehicle_docrcexpiry .make_img {
  position: relative !important;
}

.vehicle_docrcexpiry .make_img .cal_img {
  position: absolute !important;
  right: 85px !important;
  top: 5px !important;
}

.main_cal_img .cal_img {
  position: absolute;
  top: 5px;
  right: 5px;
}

.bkstartrent_dd .search_l {
  width: 50%;
}

.bkstartrent_dd .search_b1 {
  width: 50%;
}

.daytoday_search_dd .search_l {
  width: 50%;
}

.daytoday_search_dd .search_b1 {
  width: 50%;
}

.company_name2 .form-group {
  position: relative;
}

.company_name2 .form-group .cal_img {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
}

.company_name2 .form-group .cal_img img {
  width: 26px;
  height: 26px;
}

.company_name2 {
  width: auto !important;
}

.reprental_srchdd77 {
  justify-content: flex-end;
}

.reprental_srchdd77 .total_amt {
  width: auto !important;
}

.main_search_dd {
  justify-content: flex-end;
}

.investor_report .total_amt {
  width: auto !important;
}

.investor_report {
  padding: 5px 15px !important;
}

.rentalpayment .fliter_button2 {
  width: auto;
  padding: 0px;
  margin-right: 5px;
}

.rentalpayment .fliter_button2 button {
  padding: 8px 16px;
}

.main_search_dd1 {
  padding: 0px !important;
}

.investor_report .cal_img {
  top: 5px !important;
  right: 10px !important;
}

.reprental_srchdd77 .fliter_button2 {
  width: auto;
  padding: 0px;
  margin-right: 5px;
}

.reprental_srchdd77 .fliter_button2 button {
  padding: 8px 16px;
}

.main_search_dd1 .fliter_button2 {
  width: auto !important;
  padding: 0px;
  margin-right: 5px;
}

.company_name23 .form-group {
  padding: 0px 5px 0px 0px;
}

.investor_drop {
  margin-right: 5px;
}

.investor_report .fliter_button2 button {
  padding: 8px 16px;
}

.vehicle_expmain {
  justify-content: space-between;
  padding: 5px 15px !important;
}

.company_name2b55 {
  margin-right: 5px;
}

.vhexarea .company_name2b55 .form-group {
  position: relative;
  padding: 0px;
}

.vhexarea .cal_img {
  position: absolute;
  top: 5px;
  right: 5px;
}

.vhexarea .total_amt {
  width: auto;
}

.vhexarea .fliter_button2 button {
  padding: 8px 16px;
}

.vhinfo_list {
  padding: 5px 15px !important;
}

.rdtPicker {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

.form-group .rdt input {
  border: none;
  background: #f5f5f5;
  padding: 10px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 0px;
  outline: none;
}

.checkout_search91 .cal_img {
  position: absolute;
  top: 5px;
  right: 5px;
}

.rdtPicker {
  min-width: 220px !important;
}

.vhavailable {
  justify-content: flex-end;
  margin-right: 15px;
}

.booking_srchmain {
  padding: 0px 6px;
  margin-top: 5px;
}



@media (min-width: 320px) and (max-width: 479px) {
  .search_date .eQmQXs {
    min-width: 285px;
  }

  .main_search_dd12new .eQmQXs {
    min-width: 285px;
  }

  .baddpersonal .eQmQXs {
    min-width: 285px;
  }

  .vehicle_geninfo .eQmQXs {
    min-width: 285px;
  }

  .vehicle_document .eQmQXs {
    min-width: 285px;
  }

  .vehicle_docexpiry .eQmQXs {
    min-width: 285px;
  }

  .vehicle_docrcexpiry .eQmQXs {
    min-width: 285px;
    right: auto;
    left: 0px;
  }

  .vehicle_maintanance .eQmQXs {
    min-width: 285px;
  }

  .vehicle_ntservice .eQmQXs {
    min-width: 285px;
    right: auto;
    left: 0px;
  }

  .vehicle_oilservice .eQmQXs {
    min-width: 285px;
  }

  .vehicle_damage .eQmQXs {
    min-width: 285px;
  }

  .vehicle_rentdamage .eQmQXs {
    min-width: 285px;
  }

  .vehicle_sold .eQmQXs {
    min-width: 285px;
  }

  .vehic_expiryhh .eQmQXs {
    min-width: 285px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .basis_price li {
    padding: 6px !important;
  }

  .vehicle_geninfo .rdtPicker {
    right: 0px;
  }

  .vehicle_docrcexpiry .rdtPicker {
    right: 0px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .debit_card_box .col-sm-6 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .search_main7 {
    margin-left: 0px !important;
  }

  .search_date {
    margin-left: 0px;
    width: 100%;
    margin-bottom: 10px;
  }

  .search_daterh {
    margin-left: 0px;
    width: 100%;
  }

  .qlookphbtn {
    display: block !important;
    padding: 20px 10px !important;
  }

  .qlookphbtn .rdt {
    margin-bottom: 5px;
  }

  .qlookbtn {
    margin-left: 0px;
  }

  .main_search_dd12new {
    margin-bottom: 5px !important;
    display: block !important;
  }

  .main_search_dd12new .form-group {
    margin: 0px !important;
  }

  .search_date .form-group {
    padding: 0px;
  }

  .vehicles_mainsearch {
    display: block;
  }

  .vehicle_search_dd {
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }

  .vehicle_filterbtn {
    margin: 0px 0px 5px 0px;
  }

  .booking_search_dd {
    margin-bottom: 10px;
  }

  .booking_searchmain {
    display: block;
  }

  .booking_filterbtn {
    margin: 0px 5px;
  }

  .booking_filterbtn button {
    margin: 0px;
  }

  .booking_startrent {
    display: block;
  }

  .daytoday_search_dd {
    margin-bottom: 10px;
  }

  .daytoday_btn {
    margin: 0px 5px;
  }

  .closed_search_b22 .main_search_dd12 {
    display: block;
  }

  .closed_search_b22 .form-group {
    padding: 0px;
    margin-bottom: 10px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .customer_listb33 {
    display: block;
  }

  .clist_search_dd {
    margin-bottom: 10px;
  }

  .clist_btn {
    margin: 0px 5px;
  }

  .investor_mainbb2 {
    display: block;
  }

  .investor_search_dd {
    margin-bottom: 10px;
  }

  .investor_btnb22 {
    margin: 0px 5px;
  }

  .rental_mainpay {
    display: block !important;
  }

  .rental_mainpay .rentex_customer {
    margin-bottom: 10px;
  }

  .rental_mainpay .main_search_dd1 {
    display: block;
  }

  .rentalpayment .company_name2 {
    width: 100%;
    margin-bottom: 10px;
  }

  /* .rentalpayment .total_amt {
    width: 100% !important;
  } */

  .pendpay_main {
    display: block !important;
  }

  .pendpay_main .form-group {
    width: 100% !important;
    padding: 0px;
  }

  .chsearch_tt .search_l {
    width: 100%;
  }

  .chsearch_tt .form-group {
    padding: 0px;
    margin-bottom: 10px;
  }

  .vehic_expiryhh .form-group {
    width: 100% !important;
    padding: 0px;
  }

  .closed_search_b22 .search_l {
    padding-right: 0px;
  }

  .booking_searchn44 {
    padding: 0px;
  }

  .booking_srchmm {
    margin-bottom: 10px;
  }

  .bookingflbtn {
    margin: 0px;
  }

  .checkout_search9 .search_l22 {
    width: 100%;
  }

  .checkout_search9 .search_b1 {
    width: 100%;
  }

  .checkout_search9 .search_main7 {
    width: 100%;
  }

  .checkout_search91 .search_l22 {
    width: 100%;
  }

  .checkout_search91 .search_b1 {
    width: 100%;
  }

  .checkout_search91 .search_main7 {
    width: 100%;
  }

  .checkout_search91 .form-group {
    width: 100%;
    margin-left: 0px;
  }

  .vehiclelist_v777 {
    display: block !important;
  }

  .search_l22 {
    margin: 0px 0px 10px 0px !important;
  }

  .vhsearch_l {
    margin: 0px 0px 10px 0px !important;
  }

  .vhsearch_b2 {
    margin-bottom: 10px;
  }

  .vehicle_filterbtn button {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .vehiclelist_v777 .location {
    padding: 11px 0px !important;
  }

  .checkout_investor .investor_btnb22 {
    text-align: left;
    margin: 10px 0px 0px 0px;
  }

  .checkout_investor .search_l22 {
    width: 100%;
  }

  .checkout_investor .search_b1 {
    width: 100%;
  }

  .checkout_investor .search_main7 {
    width: 100%;
  }

  .checkout_investor .form-group {
    width: 100%;
    margin-left: 0px;
  }

  .investor_btnb22 button {
    margin-left: 0px;
  }

  .investor_search_dd .search_l {
    width: 100%;
  }

  .closed_search_b33 .search_l {
    width: 100%;
    padding-right: 0px;
  }

  .closed_search_b33 .search_b1 {
    width: 100%;
  }

  .closed_search_b33 .form-group {
    width: 100%;
    padding: 6px 0px;
  }

  .vehicle_search_dd .search_l {
    width: 100% !important;
  }

  .vehicle_search_dd .search_b1 {
    width: 100% !important;
  }

  .rdtPicker {
    min-width: 200px !important;
  }

  .add_personbtn button.submitbutton {
    margin: 0px;
  }

  .rentalpayment .form-group {
    padding: 0px 0px 10px 0px !important;
  }

  .main_search_dd1 {
    display: block;
  }

  .ex_customer.ex_cust {
    margin-bottom: 10px;
  }

  .reprental_srchdd77 {
    display: block !important;
  }

  .reprental_srchdd77 .form-group {
    padding: 0px 0px 10px 0px !important;
  }

  .rentalpayment .fliter_button2 {
    display: block;
    text-align: left;
    margin: 0px;
  }

  .rentalpayment .fliter_button2 button {
    width: 100% !important;
    margin: 0px;
  }

  .main_search_dd1 {
    display: block !important;
  }

  .investor_drop select {
    width: 100%;
    margin-bottom: 10px;
  }

  .company_name23 .form-group {
    padding: 0px;
    margin-bottom: 10px;
  }

  .main_search_dd1 .fliter_button2 {
    display: block;
    text-align: left;
    margin: 0px;
  }

  .main_search_dd1 .fliter_button2 button {
    width: 100% !important;
    margin: 0px;
  }

  .investor_drop {
    margin-right: 0px;
  }

  .vehicle_expmain {
    padding: 5px 15px !important;
  }

  .vhexarea .company_name2b55 .form-group {
    margin-bottom: 10px;
  }

  .company_name2b55 {
    margin-right: 0px;
  }

  .vhavailable .search_main2 {
    margin-left: 0px !important;
  }

  .vehic_expiryhh .form-group .cal_img {
    top: 5px;
    right: 5px;
  }

  .pendpay_main .cal_img {
    top: 5px;
    right: 5px;
  }

  .own_button button {
    margin: 0px 10px 0px 0px;
  }

  .compst_vehicle.chat_right1 {
    padding-left: 0px;
  }

  .edit_details1bb {
    padding: 10px;
  }

  .main_tab_ {
    padding: 20px 8px;
  }

  .stnew_cls {
    display: block !important;
  }

  .stnew_cls .search_l {
    width: 100%;
  }

  .stnew_cls .search_b1 {
    width: 100%;
    margin-bottom: 10px;
  }

  .stnew_cls .bkstartrent_btn {
    margin: 0px;
  }

  .stnew_cls .bkstartrent_btn button {
    width: 100% !important;
  }

  .stnew_cls .bkstartrent_dd {
    padding: 0px;
  }

  .booking_srchmain .booking_srchmm {
    padding-right: 0px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .debit_card_box .col-sm-6 {
    width: 100%;
  }

  .money_image {
    text-align: center;
    margin-top: 20px;
  }

  .main_company .main_row .col-sm-3 {
    width: 50%;
  }

  .mobile_pass {
    margin-bottom: 25px;
  }

  .main_avilable_img {
    display: block !important;
  }

  .checkout_search9 .search_l22 {
    width: 30% !important;
  }

  .checkout_search9 .search_main7 {
    width: 30% !important;
  }

  .checkout_search91 .search_l22 {
    width: 30% !important;
  }

  .checkout_search91 .search_main7 {
    width: 30% !important;
  }

  .main_avilable_img {
    display: block;
  }

  .chsearch_tt .search_l {
    width: 100% !important;
  }

  .main_search_b1 .search_l {
    width: 50% !important;
  }

  .investor_btnb22 {
    width: 30%;
  }

  .qlookphbtn .search_daterh .rdtPicker {
    right: 0px;
    left: auto;
  }

  .total_amt .resp img {
    width: 18px;
    height: 18px;
  }

  .total_amt ul li {
    font-size: 10px;
  }

  .rentalpayment .fliter_button2 {
    width: 35% !important;
  }

  .rentalpayment .fliter_button2 button {
    padding: 10px;
    font-size: 10px;
    width: 100%;
  }

  .rentalpayment .total_amt {
    width: 65% !important;
  }

  .reprental_srchdd77 .fliter_button2 {
    width: 35% !important;
  }

  .reprental_srchdd77 .fliter_button2 button {
    padding: 10px;
    font-size: 10px;
    width: 100%;
  }

  .reprental_srchdd77 .total_amt {
    width: 65% !important;
  }

  /* .investor_drop {
    width: 30%;
  } */

  .investor_drop select {
    font-size: 10px !important;
    padding: 10px 5px !important;
  }

  .investor_report .total_amt {
    width: 65% !important;
  }

  .investor_report .fliter_button2 button {
    font-size: 10px !important;
    padding: 2px 8px;
  }

  .rdtPicker {
    right: 0px;
  }

  .company_nameddtask .rdtPicker {
    left: 0px;
    right: auto;
  }



}

@media (min-width: 992px) and (max-width: 1199px) {
  .main_avilable_img {
    display: block !important;
  }

  .rdtPicker {
    min-width: 200px !important;
  }

  .investor_report .main_search_dd1 .fliter_button2 {
    width: 40% !important;
    display: block;
    text-align: left;
  }

  .main_search_dd1 .fliter_button2 button {
    width: 100%;
  }

  .investor_report .total_amt {
    width: 60% !important;
  }
}
.login_page {
  background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
  height: 100vh;
  position: relative;
}

.login_box {
  margin: 0% auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0px 4px 10px rgb(32 32 32);
  width: 75%;
  border-radius: 10px;
}

.login_head {
  font-size: 33.81px;
  font-weight: 500;
  color: #003f7d;
  margin-bottom: 40px;
  font-family: "Montserrat";
  font-style: normal;
  line-height: 41px;
  padding-left: 7px;

}

.user_id {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}

.password {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}

.login_box .form-group {
  margin-bottom: 20px;
  position: relative;
}

.login_box .form-group::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 65%;
  z-index: 9;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
  color: #ff8e01;
}

.login_box .form-group:first-child::before {
  content: "\f007";
}

.login_box .form-group:last-child::before {
  content: "\f084";
}

.login_box input {
  padding: 12px 10px 12px 50px;
  border-bottom: 1px solid #dddddd;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  width: 100%;
  margin-bottom: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;


}

.login_btn {
  margin: 25px 0px 10px 0px;
}

.login_btn {
  text-align: right;
}

.login_btn button {
  font-size: 15px;
  background: #003466;
  color: rgb(255, 255, 255) !important;
  border: none;
  text-transform: uppercase;
  padding: 15px 50px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.version {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13.5225px;
  color: #003f7d;
  float: left;
  padding-left: 10px;
  padding-top: 10px;
}

.login_left {
  box-shadow: 0px 0px 20px rgba(0, 12, 116, 0.2);
  border-radius: 10px 0px 0px 10px;
  margin: 5px 3px;
  padding: 25px 0px;
}

.login_left img {
  width: 100%;
}

.login_right {
  padding: 4% 8%;
}

.login_box .row {
  align-items: center;
}

.required {
  padding: 5px 0px;
}

.language_select select {
  border: none;
  color: rgb(255 140 35);
  background-color: rgb(255, 255, 255);
  margin-left: 5px;
  padding: 5px;
  border-radius: 4px;
}

.language_select {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  color: rgb(255, 255, 255);
  font-size: 14px;
}


@media (max-width: 767px) {
  .login_btn {
    text-align: center;
    margin: 20px 0px;
  }

  .login_page {
    background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
    height: 100vh;
    position: relative;
    padding: 20px;
    overflow: auto;
  }

  .login_box {
    width: 90%;
  }

  .login_head {
    font-size: 30px;
    text-align: center;
  }

  .mobile_logo {
    width: auto !important;
  }

  .login_left {
    box-shadow: none;
    border-radius: 0px;
    margin: 0px;
    padding: 30px 0px 10px 0px;
    text-align: center;
  }
}
/* chat right start */
.chat_right {
  margin: 4px 0px;
  width: calc(100% - 245px);
}

.main_userControl {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid white;
  background-color: #003466;
  margin: 4px 0px;
  padding: 0px 23px;
}

.user_cntrl {
  color: #f7f7f7;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}

.user_btn {
  text-align: right;
}

.add_btn {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 11px 24px;
  font-size: 12px;
  margin: 0px 3px;
}

.add_btn:hover {
  background: #fd9a03;
}

.add_btn i {
  padding-right: 4px;
  /* color: #fd9a03; */
}

.add_btn i:hover {
  color: #003466;
}

.sec_container {
  padding: 0px 12px;
}

/* chat main con end*/

/* main user access start */
.reg_form {
  display: flex;
  text-align: left;
  margin-right: 2px;
}

.password_main {
  display: flex;
  text-align: left;
}

.default_loc {
  display: flex;
  text-align: left;
  padding: 12px 4px;
}

.user_name_main input {
  border: none;
  background: #f5f5f5;
  padding: 12px 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  margin: 4px 5px;
  outline: none;

}

.de_location select {
  width: 100%;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  height: 37px;
  border: none;
  background: #f5f5f5;
  border-radius: 2px;
}

.main_radio li input {
  margin: 5px 0px;
}

.main_radio li label {
  padding: 0px 5px;
}

.main_radio {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  background-color: rgb(245 245 245);
  border-radius: 2px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
}

.main_radio li {
  list-style-type: none;
  padding: 0px 4px;
  display: flex;
  align-items: center;
}

.main_col {
  box-shadow: 0px 0px 2px 2px rgb(0 63 125 / 10%);

}

.star_input {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ff5f5f;
  padding: 5px 11px;
}

/* main user acces end */

/* main table start */
.main_table {
  border-collapse: collapse;
}

.table_row:nth-child(even) {
  background: #f5f5f5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.table_head {
  background-color: #003466;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.table_head th {
  padding: 10px 10px;
  text-align: left;
}

.table_row td {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding: 10px 10px;
  color: #000000;
}

.user_data {
  overflow-x: auto;
  margin: 4px 0px;
  box-shadow: 0px 1px 2px 3px rgb(0 63 125 / 10%);
}

/* /* main table end  */

/* main new user start*/
.new_useracc {
  text-align: left;
  background-color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 8px 15px;
  margin: 0px 1px;
}

.agree_list {
  margin: 0px;
  padding: 0px;
}

.agree_list li {
  float: left;
  list-style-type: none;
  padding: 6px 16px;
  border-bottom: 2px transparent;
}

.agree_list li:hover {
  border: 2px solid #f7f7f7;
}

.agree_list li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #003f7d;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

.agree_list li:hover {
  border-bottom: 2px solid #003f7d;
}

.make_new {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
  padding: 5px 0px;
}

.check_btn {
  float: left;
  width: 25%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 8px 14px;
  display: flex;
  align-items: center;
}

.check_btn label {
  padding: 2px 4px;
}

.check_btn3 {
  float: left;
  width: 40%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 8px 14px;
  display: flex;
  align-items: center;
}

.check_btn3 label {
  padding: 2px 4px;
}


.enable_user {
  padding: 0px;
  margin: 0px;
  color: #ff5f5f;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  padding-left: 7px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 5px 7px;
}

li.enable_user {
  list-style-type: none;
}

li.user_log {
  display: flex;
  align-items: center;
}

.user_log label {
  padding-left: 6px;
}

.invoice_agree {
  margin: 10px 0px;
  box-shadow: 0px 0px 2px 2px rgb(0 63 125 / 10%);
}

.check_btn1 {
  width: 50%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 4px 14px;
  display: flex;
  align-items: center;
}

.check_btn1 label {
  padding: 1px 6px;
}

.config_setup {
  background-color: #c7e2f9;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003466;
  padding: 5px 7px;
  text-align: left;
}

.config_setup1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003466;
  text-align: left;
}

.configuration_setup {
  padding: 10px 10px;
}

.check_btn2 {
  width: 40%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 4px 14px;
  display: flex;
  align-items: center;
}

.eye_class {
  position: relative;
}

.eye_class input {
  padding-right: 35px;
}

.eye_class i {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 14px;
}

.main_table .rdt_TableHeadRow {
  background-color: #003f7d !important;

}

.rdt_TableHeadRow {
  /* background-color:#003f7d !important ; */

  background: #C7E2F9 !important;

}

.sc-evZas {
  color: #ffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;

}

.rdt_TableRow {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  cursor: pointer;
}

/* main new user end*/
.activeborder {
  border-bottom: 2px solid #003f7d !important;
}


@media (min-width: 320px) and (max-width: 479px) {
  .default_loc {
    display: block;
  }

  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .default_loc {
    display: block;
  }

  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sec_container .col-sm-6 {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sec_container .col-sm-6 {
    width: 100%;
  }
  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}
/* file start */
.top_cont{
    background-color: #003466;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top_cont img{
    float:left;
    padding: 5px 10px;
  
}

.icons{
    float:right;
    margin: 6px 15px;
    display:flex;
}

.icons i {
 
    padding:6px 14px;
    color: #FFFFFF;
   
}

.icons button {
   padding: 0px 10px;
    color: #FFFFFF;
    background-color: #003F7D;
    border:none;
   font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  border:1px solid #FFFFFF;
}
.user_name{
    color: #003466;
    background: #FFFFFF;
    padding: 7px 8px;
}
.name_user {
    padding: 5px;
}
.logout{
    margin-left: 5px;
}
/* file end */
/* header icon start */
.menu_h {
  padding: 0px;
  margin: 0px;
}

.menu_h li {
  float: left;
  list-style-type: none;
  width: 8.33%;
  border-right: 1px solid #f7f7f7;
  border-left: 1px solid #f7f7f7;
  border-bottom: 2px solid #f7f7f7;
  text-align: center;
}

.menu_h li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #003f7d;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  float: left;
  width: 100%;
  padding: 10px 0px;
}
.menu_h li a img {
  margin-bottom: 5px;
}
.menu_h li:hover {
  border-bottom: 3px solid #003f7d;
  background: #f7f7f7;
}

.menuActive {
  border-bottom: 3px solid #003f7d !important;
  background: #f7f7f7 !important;
}

/* header icon end */


@media (max-width: 767px) {
  .menu_h li a {
    font-size: 10px;
  }
  /* .chat_left {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    margin: 0px;
    width: 0px !important;
    opacity: 0;
    visibility: hidden;
} */
}
@media (min-width: 768px) and (max-width: 991px) {
  .menu_h li {
    width: 10%;
  }
  .menu_h li a {
    font-size: 7px;
  }
  .menu_h li a img {
    margin-bottom: 2px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .menu_h li {
    width: 10%;
  }
  .menu_h li a {
    font-size: 9px;
  }
}
@media (min-width: 1200px) and (max-width: 1365px) {
  .menu_h li {
    width: 10%;
  }
}
/* chat left start */
.chat_left {
  width: 233px;
  left: 0px;
  top: 0px;
  min-height: 100vh;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 24%);
  margin: 8px 0px;
}

.configration {
  background: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  padding: 10px 0px;
}

.configration img {
  float: left;
  margin: 2px 5px;
}

ul.company {
  padding: 0px;
  margin: 0px;
}

.company li {
  list-style-type: none;
  padding: 10px 21px;
  text-align: left;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;

}


.company li:hover {
  border-left: #003466 3px solid;
  background-color: #f5f5f5;
}

.companyActive {
  border-left: #003466 3px solid !important;
  background-color: #f5f5f5 !important;
}

.company li a {
  text-decoration: none;
  font-family: "Montserrat";
  color: #003f7d;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  padding-left: 21px;
  float: left;
  width: 100%;
}


@media (max-width: 767px) {
  .chat_left {
    float: left;
    width: 100%;
    position: relative;
    left: auto;
    top: auto;
    height: auto !important;
  }

  .chat_left .company li {
    padding: 10px 5px !important;
    float: left;
    width: 50%;
  }
}

/* chat left end */
.edit_details {
    text-align: right;
    margin: 0px 14px;
}

.rr_bross_contant {
    background-color: #C7E2F9;
    display: flex;
    text-align: center;

}

.rr_bross {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #003466;
    padding: 16px 14px;
}

.right_side_owner {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #003466;
    padding: 2px 20px;
}

.owner_nametag {
    color: #003466;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 27px;
}

.details_owner1 {
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #003F7D;
}

.details {
    padding: 34px 4px;
}

.ed_location {
    border: none;
    padding: 8px 20px;
    color: #003f7d;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    background: #ff8e01;
    margin: 0px 5px;
}





@media (max-width: 767px) {}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (min-width: 320px) and (max-width: 479px) {
    .chat_right1 .fliter_button button {
        width: 100%;
    }
}
/* company start */
.rr_bross_contant {
  background-color: #C7E2F9;
  display: flex;
  text-align: center;
}

.rr_bross {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #003466;
  padding: 16px 14px;
}

.right_side_owner {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-align: right;
  color: #003466;
  padding: 2px 20px;
}

.rr_bros_head {
  padding: 6px 16px;

}


.details_of {
  margin: 0px;
  padding: 0px;
  float: left;
}

.details_of li {
  float: left;
  list-style-type: none;
  width: 50%;
  text-align: left;
  color: #003466;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 22px 6px;
  min-height: 60px;
}

.details_of li span {
  float: right;
  padding: 0px 10px;
}

/* company end */

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media (max-width: 767px) {
  .rr_bross_contant {
    display: block;
  }

  .right_side_owner {
    text-align: left;
  }

  .details_of li span {
    padding: 0px 5px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .rr_bross_headder .col-sm-4 {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .rr_bross_headder .col-sm-4 {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .rr_bross_headder .col-sm-4 {
    width: 100%;
  }
}
.main_card {
  padding: 0px 15px;
}

.main_reservation {
  display: flex;
  align-items: center;
  padding: 20px 6px;
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
  margin-bottom: 25px;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 3px solid transparent;
}
.main_reservation:hover {
  border-bottom: 3px solid #003f7d;
}
.right_imag {
  width: 25%;
}

.leftsidel {
  width: 75%;
}

.Zeros {
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  text-align: left;
  padding-left: 10px;
}

.resrve {
  text-align: left;
  color: #333;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding-left: 10px;
}

/* all type Vehicle */
.main_circle {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
  margin: 8px 0px;
}

.main_vehicle_status {
  background-color: #c7e2f9;
  padding: 2px 4px;
}

.main_vehicle_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v_status {
  text-align: left;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 0px 14px;
}

.all_vehicle button {
  color: #003466;
  background: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 10px 10px;
  border: none;
}

.all_vehicle button i {
  padding-left: 6px;
}

.main_avilable_img {
  display: flex;
  align-items: center;
  padding: 38px 0px;
}

.colorss li {
  list-style-type: none;
  text-align: left;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.colorss span {
  padding: 0px 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #003f7d;
}

.color_blue {
  width: 30px;
  height: 30px;
  border: none;
  background-color: #003466;
}

.color_yellow {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #ff8e01;
}

.color_yellow1 {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #77A4E5;
}

.color_yellow2 {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #E9E9E9;
}

.color_yellow3 {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #A4D2FF;
}






.v_status1 {
  padding: 8px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #003f7d;
}

.phone_btn {
  display: flex;
  align-items: center;
  padding: 36px 22px;
  width: 100%;
}

.Phone_class {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 10px 0px;
  width: 20%;
  border: none;
  border-radius: 2px;
}

.Phone_class i {
  padding-left: 3px;
}

.serach_button {
  background: #c7e2f9;
  color: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 9px 4px;
  border: none;
  border-radius: 2px;
  width: 20%;
}

.serach_button i {
  padding-right: 9px;
}

.phone_input.flt {
  padding: 0px 30px;
  width: 60%;
}

.phone_input input {
  width: 100%;
  border: none;
  background: #f5f5f5;
  padding: 8px 4px;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #003f7d;
  outline: none;
}

.avile_image {
  padding: 10px 20px;
}

.main_location {
  justify-content: space-between;
}

.dashbtm_card {
  margin-top: 32px;
}

.dashbtm_card {
  margin-bottom: 27px;
}

@media (min-width: 480px) and (max-width: 639px) {
  .main_card .col-sm-2 {
    width: 50%;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .main_card .col-sm-2 {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main_card .col-sm-2 {
    width: 33.33%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main_card .col-sm-2 {
    width: 33.33%;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .resrve {
    font-size: 11px;
  }
}


@media (max-width: 767px) {
  .main_avilable_img {
    align-items: center;
    padding: 46px 0px;
    display: block;
  }

  .main_color {
    padding: 38px 0px;
  }
}
input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.main_padding {
    padding: 0px 12px;
}

.gen-plnnrdiv {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.general-main {
    color: #003466;
    background-color: #C7E2F9;
}

.general-plannr {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.check_show {
    display: flex;
    align-items: center;
    padding: 0px 20px
}

.general-chckbox {
    display: flex;
    padding: 6px 4px;
    align-items: center;
}

.gen-check {
    display: flex;
    align-items: center;

}

.inspect-inpt {
    width: 50%;
}

.inspect-inpt input {
    width: 100%;
}

.inspect-inpt input#Advance {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    background: #F5F5F5;
    border-radius: 2px;
    min-height: 37px;
    text-align: left;
    border: none;
    outline: none;
}


.inspect {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    padding: 0px 10px;
}

.show {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    padding-left: 5px;

}

.advance {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    width: 50%;
    padding-left: 10px;
}

.general-form {
    display: flex;
}

.general-mainpckdrp {
    color: #003466;
    background-color: #C7E2F9;
}

.general-pickdrop {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.general-pickup {
    display: flex;
    padding: 14px 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.slectpickup {
    width: 100%;
    min-height: 37px;
    margin: 12px 0px;
    border: none;
    background: #F5F5F5;
    outline: none;
}


label.pickup {
    padding: 0px 6px;
}

.drop-location {
    padding: 0px 5px;
}

.tab_input.flt {
    padding: 0px 5px;
}

.slectdrop {
    width: 100%;
    min-height: 37px;
    outline: none;
    margin: 12px 0px;
    border: none;
    background: #F5F5F5;
}

.gen-pckdrpdiv {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.chng-location {
    display: flex;
}

.chng-loc {
    padding: 0px 6px;
}

.gen-srvceint {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-service {
    margin: 6px 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
}

.general-km {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 34px 10px;
    color: #003466;
}

.months {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    padding: 34px 0px;
}

.gen-or {
    text-align: center;
    padding-top: 34px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
}

.gen-km {
    display: flex;
}

.Months-inpt input#Months {
    background: #F5F5F5;
    border-radius: 2px;
    min-height: 37px;
    text-align: center;
    margin: 22px 10px;
    border: none;
}

.genkm-inpt input#km {
    background: #F5F5F5;
    border-radius: 2px;
    min-height: 37px;
    text-align: center;
    margin: 22px 10px;
    border: none;
}

.general-interval {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.gen-kmmonth {
    display: flex;
}

.fuel-chrg {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.gen-mainregnl {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-reminders {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-fuelchrgmain {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
}

.genmain-fuelchrg {
    margin: 6px 0px;
}

.gen-fuelmangmnt {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-calf {
    padding: 24px 10px;
}

.gen-calfuel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.gen-Apply {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.fuel-standchrg {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.gen-fuelstandchrg input#fuelstand {
    padding: 6px 0px;
    text-align: left;
    width: 82%;
    min-height: 34px;
    border: none;
    background: #F5F5F5;
    outline: none;
}

.traffic-fineagrmnt {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 25px 10px 0px 10px;
}

.gen-nrmlchrg {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.fuel-chrgselct {
    padding: 0px 0px;
    text-align: center;
    width: 82%;
    min-height: 40px;
    border: none;
    background: #F5F5F5;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.weekdays {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    text-align: center;
    padding: 31px 0px;
}

.weekdays-inpt {
    padding: 24px 10px;
}

.gen-fuel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.fuelnrml-stndrd {
    display: flex;
    margin: 0px 0px 27px 10px;
}

.gen-remndr {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-rmdrpln {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #003466;
    padding: 10px 10px;
}

.remndr-weeks {
    display: flex;
}

.gen-mainregnl {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-reginl {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-reginlsettng {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.gen-mainDstnce {
    display: flex;
}

.dstnce-Strng {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.dstnce-Strngselct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 50%;
    min-height: 37px;
    outline: none;
    margin: 12px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.curency-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 82%;
    min-height: 37px;
    outline: none;
    margin: 12px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.conversion-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 82%;
    min-height: 37px;
    outline: none;
    margin: 12px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.coverstion-inpt input#conversion {
    padding: 6px 5px;
    outline: none;
    width: 82%;
    min-height: 38px;
    margin: 11px 10px;
    background: #F5F5F5;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.month-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 42%;
    min-height: 37px;
    outline: none;
    margin: 16px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.mainvhcl-avalblty {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-avalblty {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-Curency {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.genvhcl-avalblty {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.genmain-trfficfines {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.after-check {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.after-check label {
    padding-left: 5px;
}

.rent-end {
    display: flex;
    align-items: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.rent-end label {
    padding-left: 5px;
}

.fines-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 42%;
    min-height: 37px;
    text-align: center;
    margin: 12px 10px;
    border: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.req_field1 {
    font-size: 10px;
    padding: 3px 10px;
    color: #b42b2b;
}

@media (min-width: 320px) and (max-width: 479px) {
    .check_show {
        padding: 0px;
    }

    .inspect {
        padding: 0px 5px;
    }
}

@media (min-width: 640px) and (max-width: 767px) {
    .chat_right1 .main_padding .col-sm-6 {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .chat_right1 .main_padding .col-sm-6 {
        width: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .chat_right1 .main_padding .col-sm-6 {
        width: 100%;
    }
}
.ul .days_rate {
  margin: 0px;
  padding: 0px;
}


.activeborder {
  border-bottom: 2px solid #003f7d !important;
}

.button_style_available {
  padding: 6px 21px;
  background: #003F7D;
  color: #ffff;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_onrent {
  padding: 6px 22px;
  background: #FF8E01;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}


.button_style_sold {
  padding: 6px 29px;
  background: #E9E9E9;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_maintainance {
  padding: 6px 4px;
  background: #A4D2FF;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_service {
  font-family: "Montserrat";
  padding: 6px 19px;
  background: #77A4E5;
  color: #ffff;
  border-radius: 3px;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_expiry {

  color: #f80606 !important;

}
/* .bnAwAJ{
 width: 150px;
}
.dBbhDz div:first-child{
  overflow: inherit;
} */
.main_vehicle_info {
  background-color: #003f7d;
  margin-top: 6px;
  padding: 4px 0px;
}

.vehicle_info {
  margin: 0px;
  padding: 0px;
}

.vehicle_info li {
  float: left;
  list-style-type: none;
  display: inline;
  padding: 6px 12px;
  margin: 0px 2px;
}


.vehicle_info li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

.insurance_info {
  margin: 0px;
  padding: 0px;
}



.insurance_info li:hover {
  border-bottom: 2px solid #003f7d;
}

.activeborder {
  border-bottom: 2px solid #003f7d !important;
}

.main_general {
  padding: 0px 6px;
}

.general_informaion {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.days_rate {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 50%;
}

.company_name3 {
  padding: 6px 8px;
}

.main_grand_total.flt {

  padding: 0px 10px;
}

.main_box_shadow {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.rates_details {
  padding: 2px 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #003f7d;
  font-weight: 500;
  text-align: left;
}

.days_rate li {
  float: left;
  list-style-type: none;
  width: 50%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 8px 6px;
}

.days_rate li span {
  float: right;
  padding: 0px 10px;
}

.ul_background {
  background-color: #f7f7f7;
}

ul.basis_price {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;

}

.basis_price li input {
  width: auto !important;
  margin-right: 5px;
}

.basis_price li {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 10px;
}



.main_apply {
  display: flex;
  align-items: center;
}

ul.apply_days {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.apply_days li input {
  width: auto !important;
  margin-right: 5px;
}

li.apply_monthly {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 26px 0px;
}

.main_button_col {
  display: flex;
  justify-content: space-between;
}

.toggle_padding {
  padding: 0px 8px;
}

.action_label {
  display: flex;
  align-items: center;
  padding: 4px 0px;
}

.active_label {
  margin-right: 10px;
}

.main_apply button {
  background: #003f7d;
  border-radius: 2px;
  color: #ffffff;
  border: none;
  padding: 8px 25px;
}

.main_rental_details {
  text-align: left;
  padding: 12px 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #003466;
}

.rental_det.flt {
  padding: 10px 2px;
}

/* pop up start */
#myModal {
  display: block !important;
}

.main_clone {
  background: #003f7d;
  display: flex;
  text-align: center;
}

.clone_vehicle {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 11px 14px;
}

.clone_vehicle img {
  padding: 0px 6px;
}

.vehicle_search {
  padding: 7px 15px;
}

.vehicle_search input {
  border: none;
  background: #c7e2f9;
  padding: 6px 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
}

.close_btn {
  text-align: right;
}

.close_button {
  border: none;
  padding: 2px 15px;
}

/* pop up end */
@media (max-width: 767px) {
  .insurance_info li {
    padding: 6px 6px;
  }
}

.action_label {
  display: flex;
  align-items: center;
  padding: 0px 0px;
}

.active_label {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  padding: 0px 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFE9E9;

  border: 1px solid #DE1B1E;
  border-radius: 4px;
  transition: .4s;
  height: 22px;
  width: 50px;


}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  border-radius: 4px;
  background-color: white;
  transition: .4s;

}

input:checked+.slider {

  background: #83F8A6;

  border: 1px solid #007222;
  height: 22px;
  width: 50px;

}

input:focus+.slider {
  box-shadow: 0 0 1px rgb(131, 248, 166);
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle_padding {
  padding: 0px 8px;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.E_comp_details12 {
  padding: 6px 0px;
}

.vehicle_infor {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}
input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.red {
  color: red;
}

.green {
  color: green;
}

.viewdata {
  padding: 6px 16px;
  background: #E6F2FF;
  border: 1px solid #9ECFFF;
  border-radius: 3px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #003F7D;
}

.button_style1 {
  padding: 6px 16px;
  background: #FFC4C4;
  border-radius: 3px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style {
  padding: 6px 16px;
  background: #D0FFC5;
  border-radius: 3px;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 18px;
}


button.per_info {
  background: none;
  border: none;
  color: #003F7D;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
}

.button_style_onrent {
  padding: 6px 16px;
  background: #FF8E01;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style_completed {
  padding: 6px 16px;
  background: #09f709;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style_completed1 {
  padding: 6px 33px;
  background: #D0FFC5;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #00610A;
}
.mobile_pass {
  background-image: url(../../static/media/new-agreement-bg.69d5c982.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main_book {
  padding: 20px 0px;
}
.numdays {
    color: #f94155;
}
.bookenddate .labelName {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bookenddate .labelName .numdays {
    margin-top: -15px;
}
.main_booking button {
  border: none;
  padding: 12px 2px;
  background: #003f7d;
  border-radius: 2px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  width: 50%;
  text-align: center;
  border: 1px solid transparent;
  font-weight: 500;
}

.main_booking button:hover {
  background: #ffffff;
  color: #003f7d;
  border: 1px solid #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
}

.exp_date {
  padding: 8px;
  border: 2px solid;
  background-color: #e7fffd;
}

.delete_btn1 {
  opacity: 0.5;
  pointer-events: none;
  border: none;
  padding: 8px 20px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #ff8e01;
}

.button_style {
  padding: 6px 16px;
  background: #d0ffc5;
  border-radius: 3px;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 18px;
}

.viewdata {
  padding: 6px 16px;
  background: #e6f2ff;
  border: 1px solid #9ecfff;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #003f7d;
}

.button_style1 {
  padding: 6px 16px;
  background: #ffc4c4;
  border-radius: 3px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.Main_img_button {
  padding: 10px 10px;
  text-align: center;
}

.agedata span {
  background: #e3f6ff;
  border: 1px solid #003f7d;
  border-radius: 2px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #003f7d;
}

.main_booking {
  margin: 25px 0px;
}

.Main_img_button img {
  padding: 20px 0px;
}

/* booking start */

.booking_vehicle {
  text-align: left;
  padding-left: 12px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.Search_but {
  padding: 22px 0px;
}

.Search_but button {
  padding: 10px 0px;
  width: 100%;
  background: #003f7d;
  color: #ffffff;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border-radius: 2px;
}



/* rental rates start */
.main_car_details {
  padding: 30px 15px;
}

.car_details {
  text-align: left;
  background-color: #c7e2f9;
  padding: 10px 6px;
  border-bottom: 1px solid #003f7d;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.details_car {
  background: #f5f5f5;
  padding: 30px 15px;
  text-align: left;
  display: flex;
}

.details_car img {
  background-color: #ffffff;
  padding: 12px 0px;
}

ul.make_details {
  margin: 0px;
  padding: 0px;
  float: left;
}

.make_details li {
  float: left;
  list-style-type: none;
  width: 35%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 16px;
}

.make_details li span {
  float: right;
}

.upload_text {
  text-align: left;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 0px;
}

/* rental rates end */

/* popup start */
.modal1 {
  opacity: 1;
  position: fixed;
  z-index: 999;
  display: block;
}

.book_st {
  display: flex;
  text-align: center;
  padding: 0px 45px;
}

.pop_car.flt {
  display: flex;
  justify-content: center;
}

/* popup end */
.otp_back {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  width: 50%;
  border: none;
  height: 40px;
  margin: 0px 10px;
  border-radius: 3px;
}

.otp_button {
  display: flex;
  align-items: center;
  margin: 40px 0px;
}

.otp_back:hover {
  background: #ffffff;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003466;
}

.otp_code.flt {
  display: flex;
  justify-content: space-between;
}

.otp_code input {
  margin: 0px 5px;
  text-align: center;
  color: #003466;
}

.verify_msg.flt {
  margin: 12px 0px;
  background: #cae5ff;
  padding: 10px 10px;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #003f7d;
}

.success_img img {
  margin: 0px 5px;
}

.verify_msg1.flt {
  margin: 12px 0px;
  background: #fff8f8;
  padding: 10px 10px;
  color: #f00000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #f33e3e;
}

.resend_otp.flt {
  padding: 10px 12px;
}

.re_button {
  width: 100%;
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  border-radius: 2px;
  padding: 15px 0px;
}

.rent_walk {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 15px 10px;
}

.ex_customer.flt {
  display: flex;
  align-items: center;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  padding: 15px 10px;
}

.main_search_b2 {
  display: flex;
  background: #9dd2ff;
  align-items: center;
}

.main_search_b2 img {
  /* width: 30px;
  height: 22px; */
  display: flex;
  align-items: center;
  margin-left: 15px;
}



.react-datetime-picker__wrapper {
  border: none !important;
}

button.react-datetime-picker__clear-button.react-datetime-picker__button {
  display: none;
}

button.react-datetime-picker__calendar-button.react-datetime-picker__button {
  display: none;
}

.invalid-feedback {
  display: block !important;
  font-size: 10px !important;
  padding: 10px;
}

/* media query */
@media (max-width: 767px) {
  .make_details li {
    padding: 5px 6px;
    width: 40%;
  }
}

.is-invalid {
  border: 1px solid red !important;
}

.delete_btn123 {
  pointer-events: none;
  opacity: 0.5;
  width: 20%;
  border: none;
  padding: 10px 0px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #003f7d;
}


.dateform {
  background: #e3f6ff !important;
  border: 1px solid #003f7d !important;
  border-radius: 2px !important;
  padding: 10px 20px !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #003f7d !important;
}

.insuranceinfo_show {
  pointer-events: none;
  opacity: 0.5;
}

.insuranceinfo_show1 {
  pointer-events: visible !important;
  opacity: 1 !important;
}

/* popup payment successfull start */

.payment_success.flt {
  text-align: center;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20.1951px;
  line-height: 25px;
}

.modal {
  background: #000000ba;
}

.vehicle_due.flt {
  padding: 15px 75px;
}

.company_namee textarea {
  border: none;
  background: #f5f5f5;
  padding: 2px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  height: 70px;
}

.resend_otp1.flt {
  padding: 10px 8px;
}

.re_button1 {
  margin: 5px 0px;
  width: 100%;
  background: #ffff;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003f7d;
  border-radius: 2px;
  padding: 15px 0px;
}

.re_button2 {
  pointer-events: none;
  opacity: 0.5;
}

.re_button1:hover {
  background-color: #003f7d;
  color: #ffff;
}

.close_button {
  border: none;
  padding: 2px 15px;
  background: none;
}

/* popup payment successfull end */

.dMmUVV:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.button_style1 {
  color: #a30000;
  padding: 10px 10px;
}

.wfIwh {
  width: 100%;
  padding: 10px !important;
  background: white;
  border: none;
  border-radius: 1px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: #003466;
}

/* car Exchange start */

.payment_success1 {
  text-align: center;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20.1951px;
  line-height: 25px;
}

.main_old_vehicle.flt {
  padding-top: 20px;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: #316191;
}

.main_old_vehicle span {
  color: #ffa412;
}

.resend_otp2.flt {
  text-align: center;
  padding-top: 30px;
}

.re_button12 {
  margin: 5px 0px;
  width: 90%;
  background: #ffff;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003f7d;
  border-radius: 2px;
  padding: 15px 0px;
  text-align: center;
}

.re_button12:hover {
  background-color: #003f7d;
  color: #ffff;
}


.button_style_onrent {
  padding: 6px 22px;
  background: #FF8E01;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style_completed {
  padding: 6px 16px;
  background: #D0FFC5;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #00610A;
}

.button_style_pending {
  padding: 6px 22px;
  background: #FFC4C4;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #A30000;
}

.button_style_completed1 {
  padding: 6px 33px;
  background: #D0FFC5;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #00610A;
}

/* car Exchange end */
.incomplete {
  margin-left: 7px;
}

@media (min-width: 320px) and (max-width: 479px) {
  .main_company .main_row .col-sm-3 {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .main_company .main_row .col-sm-3 {
    width: 50%;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .main_company .main_row .col-sm-3 {
    width: 50%;
  }

  .main_company .main_row .col-sm-4 {
    width: 50%;
  }

  .main_car_details .col-sm-4 {
    width: 100%;
  }

  .main_grand_total .row {
    width: 100%;
    float: left;
  }

  .main_grand_total .row .col-sm-6 {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .main_search_b1 {
    display: block;
  }

  .main_search_dd12 {
    width: 100%;
    padding: 0px;
  }

  .search_l {
    width: 100%;
    margin: 10px 0px;
  }

  .search_b1 {
    width: 100%;
  }

  .main_search_b1 {
    display: block;
    padding: 0px 20px 10px 20px !important;
  }

  .main_search_dd12 .form-group {
    padding: 0px;
  }

  .main_search_dd12 .form-group:nth-child(2) {
    margin-left: 10px;
  }

  .main_search_dd12 .form-group:nth-child(1) {
    margin-right: 10px;
  }

  .main_ownbutton {
    display: block !important;
  }

  .main_ownbutton .own_button {
    padding: 10px;
  }
  .own_button button {
    width: 100%;
    margin-bottom: 10px;
}
.money_image {
  margin-top: 20px;
}
.money_image img {
  width: 100%;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .main_location .location {
    width: 33%;
  }

  .main_search_b1 .search_l {
    width: 50%;
  }

  .main_search_dd12 {
    padding: 0px;
  }

  .main_search_dd12 .form-group {
    padding: 6px 2px;
  }

  .main_car_details .col-sm-4 {
    width: 100%;
  }

  .delete_btn12 {
    width: 25%;
  }

  .main_company .main_row .col-sm-4 {
    width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main_location .location {
    width: 40%;
  }

  .main_car_details .col-sm-4 {
    width: 60%;
  }

  .main_company .main_row .col-sm-4 {
    width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .main_location .location {
    width: 50%;
  }
}


/* payment start */
.form-group1 {
font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
    text-align: left;
  background: #C7E2F9;
border: 1px solid #E0F0FF;
}
.make_img1 {
    display: flex;
    align-items: center;
}

.make_img1 i{
    padding: 15px 15px;
    background:#ffffff;
    margin-right: 2px;

}


.company_name1 input {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}


.form-group4 {
font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
    text-align: left;
 

}
.make_img4 {
    display: flex;
    align-items: center;
}

.make_img4 i {
   padding: 14px 15px;
    background:#ffffff;
    margin-right: 2px;
        border-radius: 2px;
}

.company_name4 input {
    border: none;
  background-color: #ffffff;
    padding: 12px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.pay_mode.flt {
    display: flex;
    align-items: center;
    background: #C7E2F9;
border: 1px solid #E0F0FF;
}

.main_helmet_padd.flt {
    padding: 0px 8px;
}

.helmet_change.flt {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 0px 8px;
}

.total_rental.flt {
    padding: 15px 0px;
}

.top_helmet_pad.flt {
    padding: 10px 0px;
}

.credit_card {
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;

}
.credit_card img {
  padding: 0px 5px;

}

.credit_card_type {
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}
.make_im {
  display: flex;
}

.make_im img{
 padding: 10px;
 background-color: #ffffff;
}
.company_name8 input {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.company_name8 select {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.main_pay_debit.flt {
    background: #C7E2F9;
    border: 1px solid #E0F0FF;
        padding-bottom: 60px;

}

.cvv_number {
    display: flex;
    padding: 7px 0px;
    
}
.cvv_date input{
    width: 100%;
  padding: 12px 5px;
    border: none;
  outline: none;
  border-radius: 2px;
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}

.ex_date input {
  width: 100%;
 padding: 12px 5px;
  border: none;
  outline: none;
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}
label.cvv_num {
    color: #003F7D;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 6px 3px;
}
.trans_img {
    font-size: 12px;
    color: red;
}
.cvv_date {
    padding: 0px 5px;
}
.ex_date {
    padding: 0px 5px;
}

.main_atm_card.flt {
    padding: 15px 2px;
}

.info_circle.flt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info_circle i {
  font-size: 13px;
}


.info_pop {
    position: relative;
        display: flex;
    align-items: center;
}

.info_pop i {
    cursor: pointer;
}

.hover_me {
    position: absolute;
    left: -33px;
    top: -38px;
    background-color: #003F7D;
    padding: 4px 10px;
    border-radius: 30px;
    box-shadow: 0px 0px 6px rgb(188 188 188);
    display: none;
    color: #ffff;
}

.hover_me::before {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: -13px;
    font-size: 25px;
    color: #003F7D;
}

.info_pop:hover .hover_me {
  display: block;
}

.total_amt {
    background-color: #123f7d;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    width: 60%;
    border: 1px solid #FF8E01;
    display: flex;
    align-items: center;

}

.ex_cust {
    width: 22%;
}

.main_search_dd1 {
    display: flex;
    align-items: center;
    /* width: 100%; */
    padding: 0px 10px;
}

.company_name2 {
    width: 50%;
    padding: 0px !important;
}

.total_amt ul li {
    list-style: none;
    display: inline-block;

}

.main_search_b22 {
    margin-left: 0px !important;
}

.resp img {
    margin-left: 0px !important;

}

.resp {
    border-right: 1px solid #FF8E01;
    padding: 10px;
}

.total_amt ul {
    padding: 10px;
    margin: 0px;
}

.colon_span {
    padding: 0px 10px;
}

.main_vehicle_info1 {
    padding: 0px !important;
}

.vehicle_info1 li {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    padding: 14px 12px !important;
}

.vehicle_info1 li:hover {
    background-color: white;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #003466;
}


.vehicle_info1 li.menuActive a {
    color: #003466;
}

.main_vehicle {
    padding: 1px 0px 0px;
}
.total_amt {
    background-color: #123f7d;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    width: 60%;
    border: 1px solid #FF8E01;
    display: flex;
    align-items: center;

}

.ex_cust {
    width: 22%;
}

.main_search_dd1 {
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.company_name2 {
    width: 50%;
    padding: 0px !important;
}

.total_amt ul li {
    list-style: none;
    display: inline-block;

}

.main_search_b22 {
    margin-left: 0px !important;
    padding: 5px 0px;
}

.resp img {
    margin-left: 0px !important;

}

.resp {
    border-right: 1px solid #FF8E01;
    padding: 10px;
}

.total_amt ul {
    padding: 10px;
    margin: 0px;
}

.colon_span {
    padding: 0px 10px;
}

.main_vehicle_info1 {
    padding: 0px !important;
}

.vehicle_info1 li {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    padding: 14px 12px !important;
}

.vehicle_info1 li:hover {
    background-color: white;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #003466;
}

@media (min-width: 320px) and (max-width: 479px) {
    .company_name2 {
        width: 100%;
    }
}
.maintenance.flt {
    background: #c7e2f9;
    color: #003466 !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    padding: 13px;
    font-size: 16px;
}

.investor_drop select {
    padding: 8px 14px;
    border: none;
    color: #003466 !important;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 12px;
    background-color: #ffff;
    outline: none;

}
.total_amt {
    background-color: #123f7d;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    width: 60%;
    border: 1px solid #FF8E01;
    display: flex;
    align-items: center;

}

.ex_cust {
    width: 22%;
}

.main_search_dd1 {
    display: flex;
    align-items: center;
    /* width: 100%; */
    padding: 0px 10px;
}

.company_name2 {
    width: 50%;
    padding: 0px !important;
}

.total_amt ul li {
    list-style: none;
    display: inline-block;

}

.main_search_b22 {
    margin-left: 0px !important;
}

.resp img {
    margin-left: 0px !important;

}

.resp {
    border-right: 1px solid #FF8E01;
    padding: 8px 10px;
}

.total_amt ul {
    padding: 8px 10px;
    margin: 0px;
}

.colon_span {
    padding: 0px 10px;
}

.main_vehicle_info1 {
    padding: 0px !important;
}

.vehicle_info1 li {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    padding: 10px !important;
}

.vehicle_info1 li:hover {
    background-color: white;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #003466;
}


.vehicle_info1 li.menuActive a {
    color: #003466;
}

.main_vehicle {
    padding: 1px 0px 0px;
}

.button_style_available {
    padding: 6px 21px;
    background: #003F7D;
    color: #ffff;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_onrent {
    padding: 6px 22px;
    background: #FF8E01;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_sold {
    padding: 6px 29px;
    background: #E9E9E9;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_maintainance {
    padding: 6px 4px;
    background: #A4D2FF;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_service {
    font-family: "Montserrat";
    padding: 6px 19px;
    background: #77A4E5;
    color: #ffff;
    border-radius: 3px;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.downloadAlign {
    justify-content: flex-end;
}


/* payment start */
.form-group1 {
font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
    text-align: left;
  background: #C7E2F9;
border: 1px solid #E0F0FF;
}


.labelName2 {
    padding: 6px 4px;
}

.make_img1 {
    display: flex;
    align-items: center;
}

.make_img1 i{
    padding: 15px 15px;
    background:#ffffff;
    margin-right: 2px;

}


.company_name1 input {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}


.form-group4 {
font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
    text-align: left;
 

}
.make_img4 {
    display: flex;
    align-items: center;
}

.make_img4 i {
   padding: 14px 15px;
    background:#ffffff;
    margin-right: 2px;
        border-radius: 2px;
}

.company_name4 input {
    border: none;
  background-color: #ffffff;
    padding: 12px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.pay_mode.flt {
    display: flex;
    align-items: center;
    background: #C7E2F9;
border: 1px solid #E0F0FF;
}

.main_helmet_padd.flt {
    padding: 0px 8px;
}

.helmet_change.flt {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 0px 8px;
}

.total_rental.flt {
    padding: 15px 0px;
}

.top_helmet_pad.flt {
    padding: 10px 0px;
}

.credit_card {
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;

}
.credit_card img {
  padding: 0px 5px;

}

.credit_card_type {
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}
.make_im {
  display: flex;
}

.make_im img{
 padding: 10px;
 background-color: #ffffff;
}
.company_name8 input {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.company_name8 select {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.main_pay_debit.flt {
    background: #C7E2F9;
    border: 1px solid #E0F0FF;
        padding-bottom: 60px;

}

.cvv_number {
    display: flex;
    padding: 7px 0px;
    
}
.cvv_date input{
    width: 100%;
  padding: 12px 5px;
    border: none;
  outline: none;
  border-radius: 2px;
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}

.ex_date input {
  width: 100%;
 padding: 12px 5px;
  border: none;
  outline: none;
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}
label.cvv_num {
    color: #003F7D;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 6px 3px;
}

.cvv_date {
    padding: 0px 5px;
}
.ex_date {
    padding: 0px 5px;
}
.main_atm_card.flt {
    padding: 15px 25px;
}
.maintenance.flt {
    background: #c7e2f9;
    color: #003466 !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    padding: 13px;
    font-size: 16px;
}
.sub_details ul li{
    list-style: none;
    padding: 6px;
    font-size: 10px;
 color: #003466 !important;
    font-family: "Montserrat",sans-serif;
}
.sub_details li:nth-child(odd){
    float: left;
    width: 40%;
}
.sub_details li:nth-child(even){
    float: left;
    width: 60%;
}
.sub_details ul li span{float: right; padding: 0px 6px;}
.sub_details b span{
    text-align: center;
}
.sub_details1{
    text-align: center;
    padding: 100px;
}

.total_amt {
    background-color: #123f7d;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    width: 60%;
    border: 1px solid #FF8E01;
    display: flex;
    align-items: center;

}

.ex_cust {
    width: 22%;
}

.main_search_dd1 {
    display: flex;
    align-items: center;
    /* width: 100%; */
    padding: 0px 10px;
}

.company_name2 {
    width: 50%;
    padding: 0px !important;
}

.total_amt ul li {
    list-style: none;
    display: inline-block;

}

.main_search_b22 {
    margin-left: 0px !important;
}

.resp img {
    margin-left: 0px !important;

}

.resp {
    border-right: 1px solid #FF8E01;
    padding: 8px 10px;
}

.total_amt ul {
    padding: 8px 10px;
    margin: 0px;
}

.colon_span {
    padding: 0px 10px;
}

.main_vehicle_info1 {
    padding: 0px !important;
}

.vehicle_info1 li {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    padding: 10px !important;
}

.vehicle_info1 li:hover {
    background-color: white;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #003466;
}


.vehicle_info1 li.menuActive a {
    color: #003466;
}

.main_vehicle {
    padding: 1px 0px 0px;
}

.button_style_available {
    padding: 6px 21px;
    background: #003F7D;
    color: #ffff;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_onrent {
    padding: 6px 22px;
    background: #FF8E01;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_sold {
    padding: 6px 29px;
    background: #E9E9E9;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_maintainance {
    padding: 6px 4px;
    background: #A4D2FF;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.button_style_service {
    font-family: "Montserrat";
    padding: 6px 19px;
    background: #77A4E5;
    color: #ffff;
    border-radius: 3px;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    width: 92px;
    text-align: center;
}

.downloadAlign {
    justify-content: flex-end;
}
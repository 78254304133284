/* chat left start */
.chat_left {
  width: 233px;
  left: 0px;
  top: 0px;
  min-height: 100vh;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 24%);
  margin: 8px 0px;
}

.configration {
  background: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  padding: 10px 0px;
}

.configration img {
  float: left;
  margin: 2px 5px;
}

ul.company {
  padding: 0px;
  margin: 0px;
}

.company li {
  list-style-type: none;
  padding: 10px 21px;
  text-align: left;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;

}


.company li:hover {
  border-left: #003466 3px solid;
  background-color: #f5f5f5;
}

.companyActive {
  border-left: #003466 3px solid !important;
  background-color: #f5f5f5 !important;
}

.company li a {
  text-decoration: none;
  font-family: "Montserrat";
  color: #003f7d;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  padding-left: 21px;
  float: left;
  width: 100%;
}


@media (max-width: 767px) {
  .chat_left {
    float: left;
    width: 100%;
    position: relative;
    left: auto;
    top: auto;
    height: auto !important;
  }

  .chat_left .company li {
    padding: 10px 5px !important;
    float: left;
    width: 50%;
  }
}

/* chat left end */
.login_page {
  background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
  height: 100vh;
  position: relative;
}

.login_box {
  margin: 0% auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 10px rgb(32 32 32);
  width: 75%;
  border-radius: 10px;
}

.login_head {
  font-size: 33.81px;
  font-weight: 500;
  color: #003f7d;
  margin-bottom: 40px;
  font-family: "Montserrat";
  font-style: normal;
  line-height: 41px;
  padding-left: 7px;

}

.user_id {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}

.password {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}

.login_box .form-group {
  margin-bottom: 20px;
  position: relative;
}

.login_box .form-group::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 65%;
  z-index: 9;
  transform: translateY(-50%);
  font-size: 18px;
  color: #ff8e01;
}

.login_box .form-group:first-child::before {
  content: "\f007";
}

.login_box .form-group:last-child::before {
  content: "\f084";
}

.login_box input {
  padding: 12px 10px 12px 50px;
  border-bottom: 1px solid #dddddd;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  width: 100%;
  margin-bottom: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;


}

.login_btn {
  margin: 25px 0px 10px 0px;
}

.login_btn {
  text-align: right;
}

.login_btn button {
  font-size: 15px;
  background: #003466;
  color: rgb(255, 255, 255) !important;
  border: none;
  text-transform: uppercase;
  padding: 15px 50px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.version {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13.5225px;
  color: #003f7d;
  float: left;
  padding-left: 10px;
  padding-top: 10px;
}

.login_left {
  box-shadow: 0px 0px 20px rgba(0, 12, 116, 0.2);
  border-radius: 10px 0px 0px 10px;
  margin: 5px 3px;
  padding: 25px 0px;
}

.login_left img {
  width: 100%;
}

.login_right {
  padding: 4% 8%;
}

.login_box .row {
  align-items: center;
}

.required {
  padding: 5px 0px;
}

.language_select select {
  border: none;
  color: rgb(255 140 35);
  background-color: rgb(255, 255, 255);
  margin-left: 5px;
  padding: 5px;
  border-radius: 4px;
}

.language_select {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  color: rgb(255, 255, 255);
  font-size: 14px;
}


@media (max-width: 767px) {
  .login_btn {
    text-align: center;
    margin: 20px 0px;
  }

  .login_page {
    background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
    height: 100vh;
    position: relative;
    padding: 20px;
    overflow: auto;
  }

  .login_box {
    width: 90%;
  }

  .login_head {
    font-size: 30px;
    text-align: center;
  }

  .mobile_logo {
    width: auto !important;
  }

  .login_left {
    box-shadow: none;
    border-radius: 0px;
    margin: 0px;
    padding: 30px 0px 10px 0px;
    text-align: center;
  }
}
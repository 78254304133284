/* file start */
.top_cont{
    background-color: #003466;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top_cont img{
    float:left;
    padding: 5px 10px;
  
}

.icons{
    float:right;
    margin: 6px 15px;
    display:flex;
}

.icons i {
 
    padding:6px 14px;
    color: #FFFFFF;
   
}

.icons button {
   padding: 0px 10px;
    color: #FFFFFF;
    background-color: #003F7D;
    border:none;
   font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  border:1px solid #FFFFFF;
}
.user_name{
    color: #003466;
    background: #FFFFFF;
    padding: 7px 8px;
}
.name_user {
    padding: 5px;
}
.logout{
    margin-left: 5px;
}
/* file end */
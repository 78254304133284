.ul .days_rate {
  margin: 0px;
  padding: 0px;
}


.activeborder {
  border-bottom: 2px solid #003f7d !important;
}

.button_style_available {
  padding: 6px 21px;
  background: #003F7D;
  color: #ffff;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_onrent {
  padding: 6px 22px;
  background: #FF8E01;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}


.button_style_sold {
  padding: 6px 29px;
  background: #E9E9E9;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_maintainance {
  padding: 6px 4px;
  background: #A4D2FF;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_service {
  font-family: "Montserrat";
  padding: 6px 19px;
  background: #77A4E5;
  color: #ffff;
  border-radius: 3px;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  width: 92px;
  text-align: center;
}

.button_style_expiry {

  color: #f80606 !important;

}
/* .bnAwAJ{
 width: 150px;
}
.dBbhDz div:first-child{
  overflow: inherit;
} */
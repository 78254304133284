.main_card {
  padding: 0px 15px;
}

.main_reservation {
  display: flex;
  align-items: center;
  padding: 20px 6px;
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
  margin-bottom: 25px;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 3px solid transparent;
}
.main_reservation:hover {
  border-bottom: 3px solid #003f7d;
}
.right_imag {
  width: 25%;
}

.leftsidel {
  width: 75%;
}

.Zeros {
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  text-align: left;
  padding-left: 10px;
}

.resrve {
  text-align: left;
  color: #333;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding-left: 10px;
}

/* all type Vehicle */
.main_circle {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
  margin: 8px 0px;
}

.main_vehicle_status {
  background-color: #c7e2f9;
  padding: 2px 4px;
}

.main_vehicle_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v_status {
  text-align: left;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 0px 14px;
}

.all_vehicle button {
  color: #003466;
  background: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 10px 10px;
  border: none;
}

.all_vehicle button i {
  padding-left: 6px;
}

.main_avilable_img {
  display: flex;
  align-items: center;
  padding: 38px 0px;
}

.colorss li {
  list-style-type: none;
  text-align: left;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.colorss span {
  padding: 0px 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #003f7d;
}

.color_blue {
  width: 30px;
  height: 30px;
  border: none;
  background-color: #003466;
}

.color_yellow {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #ff8e01;
}

.color_yellow1 {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #77A4E5;
}

.color_yellow2 {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #E9E9E9;
}

.color_yellow3 {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  background: #A4D2FF;
}






.v_status1 {
  padding: 8px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #003f7d;
}

.phone_btn {
  display: flex;
  align-items: center;
  padding: 36px 22px;
  width: 100%;
}

.Phone_class {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 10px 0px;
  width: 20%;
  border: none;
  border-radius: 2px;
}

.Phone_class i {
  padding-left: 3px;
}

.serach_button {
  background: #c7e2f9;
  color: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 9px 4px;
  border: none;
  border-radius: 2px;
  width: 20%;
}

.serach_button i {
  padding-right: 9px;
}

.phone_input.flt {
  padding: 0px 30px;
  width: 60%;
}

.phone_input input {
  width: 100%;
  border: none;
  background: #f5f5f5;
  padding: 8px 4px;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #003f7d;
  outline: none;
}

.avile_image {
  padding: 10px 20px;
}

.main_location {
  justify-content: space-between;
}

.dashbtm_card {
  margin-top: 32px;
}

.dashbtm_card {
  margin-bottom: 27px;
}

@media (min-width: 480px) and (max-width: 639px) {
  .main_card .col-sm-2 {
    width: 50%;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .main_card .col-sm-2 {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main_card .col-sm-2 {
    width: 33.33%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main_card .col-sm-2 {
    width: 33.33%;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .resrve {
    font-size: 11px;
  }
}


@media (max-width: 767px) {
  .main_avilable_img {
    align-items: center;
    padding: 46px 0px;
    display: block;
  }

  .main_color {
    padding: 38px 0px;
  }
}
/* chat right start */
.chat_right {
  margin: 4px 0px;
  width: calc(100% - 245px);
}

.main_userControl {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid white;
  background-color: #003466;
  margin: 4px 0px;
  padding: 0px 23px;
}

.user_cntrl {
  color: #f7f7f7;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}

.user_btn {
  text-align: right;
}

.add_btn {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 11px 24px;
  font-size: 12px;
  margin: 0px 3px;
}

.add_btn:hover {
  background: #fd9a03;
}

.add_btn i {
  padding-right: 4px;
  /* color: #fd9a03; */
}

.add_btn i:hover {
  color: #003466;
}

.sec_container {
  padding: 0px 12px;
}

/* chat main con end*/

/* main user access start */
.reg_form {
  display: flex;
  text-align: left;
  margin-right: 2px;
}

.password_main {
  display: flex;
  text-align: left;
}

.default_loc {
  display: flex;
  text-align: left;
  padding: 12px 4px;
}

.user_name_main input {
  border: none;
  background: #f5f5f5;
  padding: 12px 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  margin: 4px 5px;
  outline: none;

}

.de_location select {
  width: 100%;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  height: 37px;
  border: none;
  background: #f5f5f5;
  border-radius: 2px;
}

.main_radio li input {
  margin: 5px 0px;
}

.main_radio li label {
  padding: 0px 5px;
}

.main_radio {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  background-color: rgb(245 245 245);
  border-radius: 2px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
}

.main_radio li {
  list-style-type: none;
  padding: 0px 4px;
  display: flex;
  align-items: center;
}

.main_col {
  box-shadow: 0px 0px 2px 2px rgb(0 63 125 / 10%);

}

.star_input {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ff5f5f;
  padding: 5px 11px;
}

/* main user acces end */

/* main table start */
.main_table {
  border-collapse: collapse;
}

.table_row:nth-child(even) {
  background: #f5f5f5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.table_head {
  background-color: #003466;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.table_head th {
  padding: 10px 10px;
  text-align: left;
}

.table_row td {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding: 10px 10px;
  color: #000000;
}

.user_data {
  overflow-x: auto;
  margin: 4px 0px;
  box-shadow: 0px 1px 2px 3px rgb(0 63 125 / 10%);
}

/* /* main table end  */

/* main new user start*/
.new_useracc {
  text-align: left;
  background-color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 8px 15px;
  margin: 0px 1px;
}

.agree_list {
  margin: 0px;
  padding: 0px;
}

.agree_list li {
  float: left;
  list-style-type: none;
  padding: 6px 16px;
  border-bottom: 2px transparent;
}

.agree_list li:hover {
  border: 2px solid #f7f7f7;
}

.agree_list li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #003f7d;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

.agree_list li:hover {
  border-bottom: 2px solid #003f7d;
}

.make_new {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
  padding: 5px 0px;
}

.check_btn {
  float: left;
  width: 25%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 8px 14px;
  display: flex;
  align-items: center;
}

.check_btn label {
  padding: 2px 4px;
}

.check_btn3 {
  float: left;
  width: 40%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 8px 14px;
  display: flex;
  align-items: center;
}

.check_btn3 label {
  padding: 2px 4px;
}


.enable_user {
  padding: 0px;
  margin: 0px;
  color: #ff5f5f;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  padding-left: 7px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 5px 7px;
}

li.enable_user {
  list-style-type: none;
}

li.user_log {
  display: flex;
  align-items: center;
}

.user_log label {
  padding-left: 6px;
}

.invoice_agree {
  margin: 10px 0px;
  box-shadow: 0px 0px 2px 2px rgb(0 63 125 / 10%);
}

.check_btn1 {
  width: 50%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 4px 14px;
  display: flex;
  align-items: center;
}

.check_btn1 label {
  padding: 1px 6px;
}

.config_setup {
  background-color: #c7e2f9;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003466;
  padding: 5px 7px;
  text-align: left;
}

.config_setup1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003466;
  text-align: left;
}

.configuration_setup {
  padding: 10px 10px;
}

.check_btn2 {
  width: 40%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 4px 14px;
  display: flex;
  align-items: center;
}

.eye_class {
  position: relative;
}

.eye_class input {
  padding-right: 35px;
}

.eye_class i {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}

.main_table .rdt_TableHeadRow {
  background-color: #003f7d !important;

}

.rdt_TableHeadRow {
  /* background-color:#003f7d !important ; */

  background: #C7E2F9 !important;

}

.sc-evZas {
  color: #ffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;

}

.rdt_TableRow {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  cursor: pointer;
}

/* main new user end*/
.activeborder {
  border-bottom: 2px solid #003f7d !important;
}


@media (min-width: 320px) and (max-width: 479px) {
  .default_loc {
    display: block;
  }

  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .default_loc {
    display: block;
  }

  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sec_container .col-sm-6 {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sec_container .col-sm-6 {
    width: 100%;
  }
  .check_btn3 {
    width: 50%;
    padding: 8px 10px;
  }
}
/* company start */
.rr_bross_contant {
  background-color: #C7E2F9;
  display: flex;
  text-align: center;
}

.rr_bross {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #003466;
  padding: 16px 14px;
}

.right_side_owner {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-align: right;
  color: #003466;
  padding: 2px 20px;
}

.rr_bros_head {
  padding: 6px 16px;

}


.details_of {
  margin: 0px;
  padding: 0px;
  float: left;
}

.details_of li {
  float: left;
  list-style-type: none;
  width: 50%;
  text-align: left;
  color: #003466;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 22px 6px;
  min-height: 60px;
}

.details_of li span {
  float: right;
  padding: 0px 10px;
}

/* company end */

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media (max-width: 767px) {
  .rr_bross_contant {
    display: block;
  }

  .right_side_owner {
    text-align: left;
  }

  .details_of li span {
    padding: 0px 5px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .rr_bross_headder .col-sm-4 {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .rr_bross_headder .col-sm-4 {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .rr_bross_headder .col-sm-4 {
    width: 100%;
  }
}
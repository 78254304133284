.main_vehicle_info {
  background-color: #003f7d;
  margin-top: 6px;
  padding: 4px 0px;
}

.vehicle_info {
  margin: 0px;
  padding: 0px;
}

.vehicle_info li {
  float: left;
  list-style-type: none;
  display: inline;
  padding: 6px 12px;
  margin: 0px 2px;
}


.vehicle_info li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

.insurance_info {
  margin: 0px;
  padding: 0px;
}



.insurance_info li:hover {
  border-bottom: 2px solid #003f7d;
}

.activeborder {
  border-bottom: 2px solid #003f7d !important;
}

.main_general {
  padding: 0px 6px;
}

.general_informaion {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.days_rate {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 50%;
}

.company_name3 {
  padding: 6px 8px;
}

.main_grand_total.flt {

  padding: 0px 10px;
}

.main_box_shadow {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.rates_details {
  padding: 2px 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #003f7d;
  font-weight: 500;
  text-align: left;
}

.days_rate li {
  float: left;
  list-style-type: none;
  width: 50%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 8px 6px;
}

.days_rate li span {
  float: right;
  padding: 0px 10px;
}

.ul_background {
  background-color: #f7f7f7;
}

ul.basis_price {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;

}

.basis_price li input {
  width: auto !important;
  margin-right: 5px;
}

.basis_price li {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 10px;
}



.main_apply {
  display: flex;
  align-items: center;
}

ul.apply_days {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.apply_days li input {
  width: auto !important;
  margin-right: 5px;
}

li.apply_monthly {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 26px 0px;
}

.main_button_col {
  display: flex;
  justify-content: space-between;
}

.toggle_padding {
  padding: 0px 8px;
}

.action_label {
  display: flex;
  align-items: center;
  padding: 4px 0px;
}

.active_label {
  margin-right: 10px;
}

.main_apply button {
  background: #003f7d;
  border-radius: 2px;
  color: #ffffff;
  border: none;
  padding: 8px 25px;
}

.main_rental_details {
  text-align: left;
  padding: 12px 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #003466;
}

.rental_det.flt {
  padding: 10px 2px;
}

/* pop up start */
#myModal {
  display: block !important;
}

.main_clone {
  background: #003f7d;
  display: flex;
  text-align: center;
}

.clone_vehicle {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 11px 14px;
}

.clone_vehicle img {
  padding: 0px 6px;
}

.vehicle_search {
  padding: 7px 15px;
}

.vehicle_search input {
  border: none;
  background: #c7e2f9;
  padding: 6px 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
}

.close_btn {
  text-align: right;
}

.close_button {
  border: none;
  padding: 2px 15px;
}

/* pop up end */
@media (max-width: 767px) {
  .insurance_info li {
    padding: 6px 6px;
  }
}

.action_label {
  display: flex;
  align-items: center;
  padding: 0px 0px;
}

.active_label {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  padding: 0px 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFE9E9;

  border: 1px solid #DE1B1E;
  border-radius: 4px;
  -webkit-transition: .4s;
  transition: .4s;
  height: 22px;
  width: 50px;


}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  border-radius: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;

}

input:checked+.slider {

  background: #83F8A6;

  border: 1px solid #007222;
  height: 22px;
  width: 50px;

}

input:focus+.slider {
  box-shadow: 0 0 1px rgb(131, 248, 166);
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle_padding {
  padding: 0px 8px;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.E_comp_details12 {
  padding: 6px 0px;
}

.vehicle_infor {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}
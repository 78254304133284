.maintenance.flt {
    background: #c7e2f9;
    color: #003466 !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    padding: 13px;
    font-size: 16px;
}

.investor_drop select {
    padding: 8px 14px;
    border: none;
    color: #003466 !important;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 12px;
    background-color: #ffff;
    outline: none;

}
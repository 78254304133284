@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-weight: bold !important;
}

.flt {
  float: left;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

/* Location Details start*/

/* company_details */

.company_hedding {
  background-color: #003f7d;
  display: flex;
  text-align: center;
  padding: 2px 16px;
  margin: 4px 0px;
}

.company_icon {
  display: flex;
  align-items: center;
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 8px 4px;
}

.company_icon img {
  padding: 2px 6px;
}

.edit_details {
  text-align: right;
}

.edit_details1 {
  padding: 30px 0px;
}

.edit_button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 11px 24px;
  font-size: 12px;
  background: #ffffff;
}

.edit_button1 {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 8px 24px;
  font-size: 12px;
  background: #edf6ff;
  border-radius: 2px;
  border: 1px solid #003f7d;
  box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
  display: flex;
  align-items: center;
}

.edit_button1 i {
  color: #ff8e01;
  padding: 0px 4px;
}

.edit_button i {
  color: #ff8e01;
  padding: 0px 4px;
}

.investor_mainbb2 .fliter_button2 {
  width: auto;
}

.investor_search_dd .search_l {
  width: 50%;
}

.Company_name {
  background: #c7e2f9;
  display: flex;
  align-items: center;
  text-align: right;
  padding: 2px 0px;
}

.comp_name_details {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #003466;
  padding: 9px 10px;
}

.comp_name_details img {
  padding: 0px 4px;
}

.E_comp_details {
  padding: 6px;
}

.main_company {
  width: 100%;
  /* box-shadow: 0px 0px 8px rgb(0 12 116 / 20%); */
  margin: 7px 0px;
  padding-bottom: 40px;
}

.Company_name_week {
  background: #c7e2f9;
  padding: 2px 0px;
}

/* .insuranceinfo_show{
  pointer-events: none;
  opacity: 0.5;
} */

/* company button start */
.user_btn {
  text-align: right;
}

.add_btn {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 11px 24px;
  font-size: 12px;
  margin: 0px 3px;
  background-color: #ffffff;
}

.add_btn:hover {
  background: #fd9a03;
}

.add_btn i {
  padding-right: 4px;
  /* color: #fd9a03; */
}

.add_btn i:hover {
  color: #003466;
}

/* company button end */

.main_monthly {
  margin: 7px 0px;
}

.form-group {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

.labelName {
  padding: 6px 4px;
}

.form-group .input {
  border: none;
  background: #f5f5f5;
  padding: 12px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
}

.form-group .input::placeholder {
  font-weight: 600;
}

.make_img {
  display: flex;
  align-items: center;
}

.make_img12 {
  display: flex;
  align-items: center;
}

.make_img12 img {
  padding: 5px 6px;
  background-color: #f5f5f5;
  margin-right: 2px;
}

.make_img i {
  padding: 11px 9px;
  align-items: center;
  margin-right: 2px;
  display: flex;
  background-color: #f5f5f5;
  font-size: 12px;
}

i.fas.fa-trash-alt {
  color: rgb(206, 26, 62);
}

.make_img button {
  /* padding: 4px 6px; */
  margin-left: 2px;
  border-radius: 2px;
  border: none;
  /* background: #C7E2F9; */
}

.company_name textarea {
  border: none;
  background: #f5f5f5;
  padding: 2px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
}

.company_name input {
  border: none;
  background: #f5f5f5;
  padding: 10px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  outline: none;
}

.company_name select {
  border: none;
  background: #f5f5f5;
  padding: 10px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  outline: none;
}

.company_main_name {
  background-color: #003f7d;
  display: flex;
  text-align: center;
  margin: 4px 0px;
}

.company_name1 {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 6px 0px;
}

.company_name1 img {
  padding: 6px 6px;
}

.upload_img_btn button {
  width: 100%;
  height: 165px;
  background-color: rgba(248, 248, 248, 1);
  border: 2px dotted rgba(118, 118, 118, 1);
  border-radius: 5px;
}

.upload_img_btn {
  position: relative;
  cursor: pointer;
}

.upload_img_btn input {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.upload_img {
  padding: 16px 18px;
}

.browse_logo_img {
  padding: 6px 0px;
  text-decoration: underline;
  color: #003f7d;
}

.main_ownbutton {
  margin: 22px 0px;
}

.own_button {
  text-align: right;
}

.own_button button {
  border: 1px solid #003f7d;
  padding: 12px 25px;
  /* width:44%; */
  margin: 0px 8px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
}

.own_button button:hover {
  background-color: #003466;
  color: #ffffff;
}

/* Location Details end */

/* filter button start */

.main_location {
  background: #003f7d;
  display: flex;
  text-align: center;
  margin: 4px 0px;
}

.location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 11px 14px;
  display: flex;
  align-items: center;
}

.location img {
  padding: 0px 6px;
}

.fliter_button {
  text-align: right;
  padding: 3px 15px;
}

.fliter_button button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 18px;
  font-size: 12px;
  margin: 0px 2px;
  background: #ffffff;
  border-radius: 2px;
}

.fliter_button i {
  padding: 0px 8px;
  color: #ff8e01;
}

.fliter_button2 i {
  padding: 0px 8px;
  color: #ff8e01;
}

.fliter_button2 button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 16px;
  font-size: 12px;
  margin: 0px 2px;
  background: #ffffff;
  border-radius: 2px;
  width: 100%;
}

/* filter button end */

/* box Shadow start */
/* .main_emission {
   box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2); 
    margin: 6px 0px;
} */

.main_Submit_btn {
  padding: 20px 0px;
}

/* box Shadow end  */

/* day to day task start */

.delete_b {
  display: flex;
  padding: 3px 14px;
  align-items: center;
  justify-content: flex-end;
}

.delete_btn {
  border: none;
  padding: 8px 20px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #ff8e01;
}

.delete_btn12 {
  width: 20%;
  border: none;
  padding: 10px 0px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #003f7d;
}

.main_boxShadow {
  box-shadow: 0px 0px 8px rgb(0 12 116 / 20%);
}

.main_search_b {
  display: flex;
  align-items: center;
}

.search_b {
  width: 100%;
  padding: 5px 10px;
  display: flex;
}

.search_b input {
  width: 100%;
  border: none;
  background: #c7e2f9;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 10px;
  color: #003466;
}

.search_button {
  background: #ffffff;
  border: none;
  padding: 4px 8px;
}

.main_box_shadow {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.main_general {
  padding: 0px 22px;
}

.main_vehicle_info {
  background-color: #003f7d;
  margin-top: 6px;
  padding: 1px 0px;
}

.vehicle_info {
  margin: 0px;
  padding: 0px;
}

.vehicle_info li {
  float: left;
  list-style-type: none;
  display: inline;
  padding: 10px 0px;
  margin: 0px 2px;
}

/* .vehicle_info li:hover {
 background-color: #FFFFFF;

} */
.vehicle_info li a:hover {
  color: #003f7d;
}

.vehicle_info li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  padding: 16px 15px;
}

.general_informaion {
  box-shadow: 0px 0px 8px rgba(0, 12, 116, 0.2);
}

.insurance_info {
  margin: 0px;
  padding: 0px;
}

.insurance_info li {
  float: left;
  list-style-type: none;
  display: inline;
  padding: 6px 0px;
  border: 2px solid #f7f7f7;
}

.insurance_info li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #003f7d;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  font-weight: 600;
  padding: 13px 16px;
}

.insurance_info li:hover {
  border-bottom: 2px solid #003f7d;
}

.insurance_infos {
  border-bottom: 2px solid #003f7d !important;
}

/* day to day task end */
/* pagination start*/

.pagination {
  display: flex;
  float: right;
  padding: 5px 5px !important;
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 10px;
}

.page-item.active .page-link {
  background-color: #003f7d !important;
}

/* .pagination a {
  background-color: #231976 !important ;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 11px;
}

.pagination a.active {
  background-color: #003f7d !important;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
} */

/* pagination end */

/* table css start */

.main_table1 {
  border-collapse: collapse;
  position: relative;
}

.main_table1 .sc-evZas {
  background: #c7e2f9;
  color: #003466 !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.main_table1 .GlssT {
  font-size: 10px;
}

.main_tablehead {
  overflow-x: auto;
  position: relative;
  min-height: 300px;
}

.main_tableheadnew {
  overflow-x: auto;
  position: relative;
  min-height: 300px;
}

.table_head1 {
  background: #c7e2f9;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.table_head1 th {
  padding: 10px 10px;
  /* text-align: left; */
}

.table_row1:nth-child(even) {
  background: #f5f5f5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.table_row1 td {
  /* text-align: left; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding: 14px 10px;
  color: #003f7d;
}

.table_row1 .select_btn {
  border-radius: 2px;
  padding: 8px 25px;
  background: #ebf6ff;
  border: 1px solid #c7e2f9;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
}

.table_row1 .select_btn2 {
  padding: 9px 25px;
  border: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background: #d0ffc5;
  color: #00610a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
}

.table_row1 .select_btn3 {
  padding: 9px 12px;
  border: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background: #ebf6ff;
  color: #a30000;
  background: #ffc4c4;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
}

/* table css end */

/* company start */
.chat_right1 {
  padding-left: 2px;
  margin: 4px 0px;
  width: calc(100% - 233px);
}

/* company end */

/* calender button  */
.dgRweL {
  width: 26px !important;
  height: 26px !important;
  position: absolute;
  cursor: pointer;
  bottom: 3px;
  margin: auto;
  right: 10px;
}

/* calender button */

.daily_basis {
  padding: 10px 0px;
}

.main_search_dd {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.search_l {
  width: 40%;
}

.search_b1 {
  width: 60%;
  display: flex;
}

.search_b1 input {
  width: 100%;
  border: none;
  background: #c7e2f9;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 10px;
  color: #003466;
  outline: none;
}

.search_l select {
  width: 100%;
  padding: 10px 0px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
}

.fliter_button2 {
  text-align: right;
  padding: 3px 20px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hestory {
  padding: 0px 6px;
  display: flex;
  align-items: center;
  color: #ffff;
}

.main_booking_info {
  background-color: #003f7d;
  padding: 15px 0px;
}

.booking_vehicle {
  text-align: left;
  padding-left: 12px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.main_ownbutton.flt {
  display: flex;
  align-items: center;
}

.own_button {
  text-align: right;
  /* / float: right; / */
}

.label_block {
  border: 1px solid #ffc3c3;
  border-radius: 4px;
  color: #003f7d;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
}

.add_cls {
  display: flex;
  align-items: center;
  padding: 0px 6px;
}

.add_cls input {
  display: flex;
  align-items: center;
  margin: 0px 6px;
}

.main_booking_info1 {
  background-color: #003f7d;
  padding: 15px 0px;
  display: flex;
  align-items: center;
}

.main_bloack {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.label_block img {
  background: #ffc3c3;
  border-radius: 4px;
  padding: 10px 10px;
}

.label_block.input {
  padding: 2px 2px;
}

/* set up vehicle start*/
.E_comp_deta.flt {
  padding: 0px 3px;
}

.main_row.flt {
  padding: 0px 10px;
}

.main_tab_.flt {
  padding: 30px 8px;
}

/* set up vehicle end*/

/* upload button start */

.up_button img {
  cursor: pointer;
}

.up_button input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.jpg_link.flt {
  display: flex;
  justify-content: space-between;
}

.frontside_img.flt {
  border: 2px dotted rgba(118, 118, 118, 1);
  border-radius: 5px;
  width: 100%;
  height: 225px;
}

.main_uploded.flt {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}

.upload_delete {
  border: none;
  margin: 0px 5px;
  width: 55px;
  background: #003f7d;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 7px 0px;
  border: 1px solid transparent;
}

.upload_delete:hover {
  background-color: #f7f7f7;
  color: #003f7d;
  border: 1px solid #003f7d;
}

.uploded.flt {
  display: flex;
  align-items: center;
}

.exit_button {
  display: flex;
  align-items: center;

  justify-content: right;
}

.updated {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #00732e;
  padding: 0px 5px;
}

.upload_padding {
  padding: 16px 5px;
}

.uploded {
  display: flex;
  align-items: center;
}

.pan_image img {
  width: 100%;
}

.updated1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 0px 5px;
  color: #a30000;
}

/* upload image 2 end*/
.close_button {
  border: none;
  padding: 2px 15px;
  margin: 0px 5px;
}

.download_button {
  padding: 7px 10px;
}

/* .up_button1 {
  position: relative;
  padding: 10px 8px;
} */

.abc {
  padding: 4px 4px;
}

.up_button {
  position: relative;
  padding: 9px 10px;
  cursor: pointer;
}

.download_button {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  margin: 0px 2px;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 5px 0px;
}

.download_button i {
  padding: 0px 5px;
}

.modal {
  background: #000000ba;
}

.labelName2 {
  padding: 6px 0px !important;
  width: 50%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

.labelName3 {
  padding: 6px 0px !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

/* pop up start */
.vehicle_due img {
  width: 100% !important;
}

/* .react-pdf__Page__canvas {
    width: 100% !important;
} */

/* .pagec {
  font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
} */

/* .buttonc button{
  background: #003F7D;
  color: #FFFFFF;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  margin: 0px 2px;
  padding:10px 10px;
} */

/* pop up end */
/* upload button end */

/* .furIyG {
  z-index: 1 !important;
} */

.cal_der {
  display: flex;
  align-items: center;
  padding: 0px 4px;
  width: 50%;
  margin: 0px 7px;
}

/* / data table css  / */
.rdt_TableRow:nth-child(even) {
  background: #f5f5f5;
}

/* / data table css  / */

/* coomingsoon start */

.cooming_soon1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  color: #003f7d;
  text-align: center;
}

.classOne {
  min-height: calc(100vh - 116px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* coomingsoon end */

/* onrent start */
.main_search_b1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 2px;
}

.main_search_dd1 {
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0px 10px;
}

/* onrent end */

/* download_button start */
.main_downld {
  text-align: center;
  padding: 8px 10px;
}

.main_downld a {
  text-decoration: none;
  padding: 10px 20px;
  color: #f7f7f7;
  background-color: #003466;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.main_payment.flt {
  /* padding-top: 20px;
   */
  padding: 0px 10px;
}

.main_headbox.flt {
  padding: 10px 12px;
}

.main_grand_total.flt {
  padding: -8px;
  padding: 0px 10px;
}

/* download_button end */

/* upload image 2 start*/
.upload_img_btn1 button {
  width: 100%;
  height: 225px;
  background-color: rgba(248, 248, 248, 1);
  border: 2px dotted rgba(118, 118, 118, 1);
  border-radius: 5px;
}

.upload_img_btn1 {
  position: relative;
  cursor: pointer;
}

.upload_img_btn1 input {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.upload_img1 {
  padding: 16px 5px;
}

.main_pay_debit1.flt {
  background: #c7e2f9;
  border: 1px solid #e0f0ff;
}

/* upload Image 2 end */

/* start issue start */

.gap_search.flt {
  display: flex;
}

/* start issue end  */

.eQmQXs {
  z-index: 9999;
}

img.cal {
  position: relative;
  left: 246px;
  /* right: 0px; */
  /* top: -18px; */
  bottom: 30px;
}

.new_location9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0px 0px;
}

.qlook_view {
  margin-top: 5px;
  margin-bottom: 30px;
}

.qlookup_table thead tr {
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat";
  background: #c7e2f9;
  color: #003466 !important;
}

.qlookup_table thead tr th {
  padding: 15px !important;
}

.qlookup_table tbody tr {
  background-color: rgb(255, 255, 255);
}

.qlookup_table tbody tr:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.qlookup_table tbody tr td {
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  padding: 15px !important;
}

.qlookbtn {
  margin-left: 5px;
}

.main_table9 {
  padding: 5px;
}

.main_table9 table {
  width: 100%;
}

/* Media Query */

@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .vehicle_info li a {
    padding: 0px !important;
  }

  .main_search_dd {
    display: block;
  }

  /* @media (max-width: 767px) { */
  .hide_mobile {
    display: none;
  }

  .ex_cust {
    width: 100% !important;
  }

  .search_b11 {
    width: 100%;
    padding: 2px 10px;
  }

  .total_amt {
    width: 100% !important;
    margin-top: 6px;
  }

  /* user */

  .top_cont {
    display: flex;
    justify-content: space-between;
  }

  .agree_list li {
    padding: 6px 6px;
  }

  .menu_h li {
    width: 33% !important;
    min-height: 72px;
  }

  .chat_right {
    width: 100% !important;
  }

  .chat_right1 {
    width: 100% !important;
  }

  .main_userControl {
    display: block !important;
    height: auto !important;
    padding: 15px 15px !important;
  }

  .user_btn {
    text-align: left !important;
    margin-top: 10px;
  }

  .add_btn {
    width: 30% !important;
    padding: 10px 4px !important;
    font-size: 12px;
    margin-right: 2%;
  }

  .main_tablehead {
    overflow-x: auto;
  }

  .main_tableheadnew {
    overflow-x: auto;
  }

  .fliter_button button {
    width: 32%;
    padding: 12px 0px;
    margin: 2px 2px;
  }

  .main_location {
    display: block;
  }

  .fliter_button {
    display: block;
    padding: 2px 2px;
    text-align: center;
  }

  .own_button {
    text-align: center !important;
    padding: 25px 10px;
  }

  .edit_details {
    text-align: right;
    margin: 0px 2px !important;
  }

  .main_search_b {
    display: inline;
    padding: 4px 0px;
  }

  .insurance_info li {
    padding: 6px 4px;
    width: 100%;
  }

  .own_button button {
    width: 40%;
  }

  .mobile_pass.flt {
    padding: 15px 0px;
  }

  .main_search_b2 {
    display: inline-block !important;
  }

  .main_search_dd {
    padding: 5px 10px;
  }

  .fliter_button2 {
    justify-content: start !important;
    padding: 3px 0px !important;
    margin: 0px 7px;
  }

  .main_search_dd1 {
    width: 100% !important;
  }

  .delete_btn123 {
    width: 100% !important;
  }

  .delete_btn12 {
    width: 100% !important;
  }

  .fliter_button2 button {
    padding: 10px 0px !important;
    width: 77% !important;
  }

  .main_general {
    padding: 0px 10px !important;
  }

  .E_comp_details {
    padding: 6px 10px !important;
  }

  .l_der {
    padding: 0px 9px !important;
    width: 100% !important;
  }

  img.cal {
    left: 295px;
  }

  /* .main_search_dd {
    display: inline-block !important;
  }  */

  .button_style_onrent {
    padding: 6px 16px !important;
  }

  .button_style_available {
    padding: 6px 15px !important;
  }

  .main_search_b1 {
    padding: 4px 0px !important;
  }

  .upload_img {
    padding: 5px 18px !important;
  }

  .viewdata {
    padding: 6px 7px !important;
    width: 100%;
  }

  .phone_btn {
    padding: 36px 6px !important;
  }

  .Phone_class {
    width: 40% !important;
  }

  .phone_input {
    padding: 0 17px !important;
    width: 40% !important;
  }

  .serach_button {
    width: 30% !important;
  }

  .edit_vehicle_fix {
    display: flex;
  }
}

@media (min-width: 768px) {
  .hide_desktop {
    display: none;
  }
}

.modal1 {
  opacity: 1 !important;
  position: fixed;
  z-index: 999;
  display: block;
}

.book_st {
  display: flex;
  text-align: center;
  padding: 0px 45px;
}

.pop_car.flt {
  display: flex;
  justify-content: center;
}

/* agreement */
.otp_back {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  width: 50%;
  border: none;
  height: 40px;
  margin: 0px 10px;
  border-radius: 3px;
}

.otp_button {
  display: flex;
  align-items: center;
  margin: 40px 0px;
}

.otp_back:hover {
  background: #ffffff;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003466;
}

.otp_code.flt {
  display: flex;
  justify-content: space-between;
}

.otp_code input {
  margin: 0px 5px;
  text-align: center;
  color: #003466;
}

.verify_msg.flt {
  margin: 12px 0px;
  background: #cae5ff;
  padding: 10px 10px;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #003f7d;
}

.success_img img {
  margin: 0px 5px;
}

.verify_msg1.flt {
  margin: 12px 0px;
  background: #fff8f8;
  padding: 10px 10px;
  color: #f00000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #f33e3e;
}

.resend_otp.flt {
  padding: 10px 12px;
}

.re_button {
  width: 100%;
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  border-radius: 2px;
  padding: 15px 0px;
}

.mob_number {
  padding: 12px 14px;
  align-items: center;
  margin-right: 2px;
  display: flex;
  background-color: #f5f5f5;
}

.main_vehicle_drop select {
  padding: 8px 5px;
  border: none;
  color: #003466;
  background-color: #ffffff;
  outline: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

/* extra css */
.agedata.flt {
  padding: 35px 6px;
}

select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__amPm {
  height: auto !important;
  width: auto !important;
}

.react-datetime-picker__inputGroup__input {
  height: auto !important;
}

.main_car_padding.flt {
  padding: 30px 10px;
}

.GlssT:not(:last-of-type) {
  border: none !important;
}

.req_field {
  padding: 3px 3px;
  color: #b42b2b;
}

/* / Car start / */
.main_car_padding.flt {
  padding: 30px 10px;
}

.make_details li {
  float: left;
  list-style-type: none;
  width: 50%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 16px;
}

.details_car {
  background: #f5f5f5;
  padding: 20px 15px;
  text-align: left;
  display: flex;
}

.But_padding {
  padding: 55px 0px;
}

.own_button1 button {
  border: 1px solid #003f7d;
  padding: 12px 50px;
  /* / width:100%; / */
  margin: 0px 8px;
  color: #f7f7f7;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background-color: #003466;
  box-sizing: border-box;
  border-radius: 2px;
}

/* / Car End / */

/* / New payment start  / */
.paid_class.flt {
  padding: 0px;
  margin: 0px;
  float: left;
}

.paid_class li {
  float: left;
  list-style-type: none;
  width: 70%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 10px;
}

.main_paid_class.flt {
  background: #c7e2f9;
  padding: 20px 0px;
  border: 1px solid #e0f0ff;
}

.grand_ttl.flt {
  padding-bottom: 10px;
  padding-right: 10px;
}

.paid_class li span {
  float: right;
}

li.pay_lst {
  width: 30%;
}

/* / New payment end / */

.main_booking_info12 {
  background-color: #003f7d;
  padding: 5px 0px;
  display: flex;
  align-items: center;
}

.grand_ttl1.flt {
  /* padding-bottom: 10px; */
  /* padding-right: 10px; */
  padding: 5px 10px;
}

.ownbutton1 {
  padding-top: 10px;
}

/* .bMgaAx div:first-child {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
} */

.rdt {
  width: 100%;
}

/* .rdt_TableRow .sc-hKMtZM:nth-child(1) {
    max-width: 70px;
}

.rdt_TableHeadRow .jzGaAN:nth-child(1) {
    max-width: 70px !important;
} */
.cGZmld {
  max-width: auto !important;
  min-width: auto !important;
}

.GlssT {
  color: rgb(0 0 0) !important;
}

.cal_img {
  position: absolute;
  right: 2%;
  top: 13%;
  bottom: 0%;
}

.main_cal_img {
  position: relative;
}

.main_search_dd12 {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.main_load img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.main_load {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
}

.rent_walk {
  padding: 15px !important;
}

.table_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
}

.table_loader img {
  width: 100%;
  height: 256px;
}

.no-data {
  text-align: center;
  padding: 10% 0;
  width: 100%;
}

.main_boxShadowouter {
  box-shadow: 0px 0px 8px rgb(0 12 116 / 20%);
  position: relative;
  min-height: 300px !important;
}

.table_head2 {
  background: #ffffff;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.table_head2 th {
  padding: 10px 10px;
  /* text-align: left; */
}

.looparea {
  margin-bottom: 0px;
  padding-right: 2rem;
}

.divider {
  border-bottom: 1px dashed #003466;
  margin: 20px 0px;
}

.search_main7 {
  width: 30%;
  margin-left: 5px !important;
}

.search_main2 {
  margin-left: 5px !important;
}

.search_date {
  margin-left: 5px;
  width: 40%;
}

.search_date input {
  font-size: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  border-radius: 0px;
  border: none;
  width: 100%;
  padding: 10px 5px;
}

/* .search_date .rdtPicker {
  left: -125px;
} */

.checkout_search9 {
  padding: 6px 15px;
}

.search_l22 {
  width: 30%;
}

.qlookphbtn .search_date input {
  font-size: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0px;
  width: 100%;
  padding: 10px 5px;
}

.qlookphbtn .search_date .rdtPicker {
  left: 0px;
}

.qlookphbtn .search_daterh input {
  font-size: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0px;
  width: 100%;
  padding: 10px 5px;
}

.qlookphbtn .search_daterh .rdtPicker {
  left: 0px;
}

.search_daterh {
  margin-left: 5px;
  width: 40%;
}

.checkout_search9 .main_search_dd12 .form-group {
  padding: 6px 0px;
  margin-right: 5px;
}

.checkout_search9 .main_search_dd12 .form-group:last-child {
  margin-right: 0px;
}

.main_search_dd12new {
  padding: 0px 5px;
}

.main_search_dd12new .eQmQXs {
  right: 0;
}

.search_date .form-group {
  padding: 6px 0px;
}

.search_date .eQmQXs {
  right: 0px;
}

.vehicles_mainsearch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 15px;
}

.vehicle_search_dd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  padding: 0px 5px;
}

.vehicle_search_dd .search_l {
  width: 50% !important;
}

.vehicle_search_dd .search_b1 {
  width: 50% !important;
}

.vehicle_filterbtn {
  padding: 0px !important;
  width: auto;
  text-align: left;
}

.vehicle_filterbtn button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 16px;
  font-size: 12px;
  margin-left: 5px;
  background: #ffffff;
  border-radius: 2px;
}

.vehicle_filterbtn button i {
  padding: 0px 8px;
  color: #ff8e01;
}

.vehicle_filterbtn.fliter_button2 button {
  margin: 0px;
}

.vehicles_mainsearch .search_l select {
  padding: 11px;
}

.vehicles_mainsearch .search_b1 input {
  padding: 11px;
}

.vehicle_geninfo .eQmQXs {
  right: 0px;
}

.vehicle_document .eQmQXs {
  left: 0px;
}

.vehicle_docexpiry .eQmQXs {
  left: 0px;
}

.vehicle_docrcexpiry .eQmQXs {
  right: 0px;
}

.vehicle_maintanance .eQmQXs {
  left: 0px;
}

.vehicle_ntservice .eQmQXs {
  right: 0px;
}

.vehicle_oilservice .eQmQXs {
  left: 0px;
}

.vehicle_damage .eQmQXs {
  right: 0px;
}

.vehicle_rentdamage .eQmQXs {
  left: 0px;
}

.vehicle_sold .eQmQXs {
  right: 0px;
}

.baddpersonal .rdtPicker {
  left: 0px;
}

.bookenddate .rdtPicker {
  right: 0px;
}

.booking_searchmain {
  display: flex;
  align-items: center;
  padding: 6px 10px;
}

.booking_searchmain .ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.booking_search_dd {
  width: auto;
  padding: 0px 5px;
}

.booking_filterbtn {
  padding: 0px !important;
  width: auto;
}

.booking_search_dd .search_l select {
  padding: 11px 0px;
}

.booking_search_dd .search_b1 input {
  padding: 11px 0px;
}

.booking_startrent {
  display: flex !important;
  align-items: center !important;
  padding: 6px 10px;
}

.booking_startrent .ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.bkstartrent_dd {
  width: auto;
  padding: 0px 5px;
}

.bkstartrent_dd .search_l select {
  padding: 11px 0px;
}

.bkstartrent_dd .search_b1 input {
  padding: 11px 0px;
}

.bkstartrent_btn {
  padding: 0px !important;
  width: auto;
}

.daytoday_main {
  padding: 5px 15px;
}

.daytoday_main .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0px !important;
}

.daytoday_search_dd {
  width: auto;
  padding: 0px 5px;
}

.daytoday_search_dd .search_l select {
  padding: 11px 0px;
}

.daytoday_search_dd .search_b1 input {
  padding: 11px 0px;
}

.daytoday_btn {
  padding: 0px !important;
  width: auto;
}

.daytoday_btn button {
  margin: 0px;
}

.closed_search_b22 {
  padding: 6px 20px;
}

.closed_search_b22 .main_search_dd12 {
  padding: 0px;
}

.closed_search_b22 .form-group {
  padding: 0px 5px 0px 0px;
}

.closed_search_b22 .search_l select {
  padding: 11px 0px;
}

.closed_search_b22 .search_b1 input {
  padding: 11px 0px;
}

.closed_search_b22 .wfIwh {
  padding: 11px 0px !important;
}

.customer_listb33 {
  display: flex;
  align-items: center;
  padding: 6px 15px;
}

.customer_listb33 .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.customer_listb33 .location img {
  padding: 0px;
  margin-right: 6px;
}

.clist_search_dd {
  width: auto;
  padding: 0px 5px;
}

.clist_search_dd .search_l select {
  padding: 11px 0px;
}

.clist_search_dd .search_b1 input {
  padding: 11px 0px;
}

.clist_btn {
  padding: 0px !important;
  width: auto;
}

.clist_btn button {
  margin: 0px;
}

.investor_mainbb2 {
  display: flex;
  align-items: center;
  padding: 6px 15px;
  justify-content: flex-end !important;
}

.investor_mainbb2 .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.investor_mainbb2 .location img {
  padding: 0px;
  margin-right: 6px;
}

.investor_search_dd {
  width: auto;
  padding: 0px 5px;
}

.investor_search_dd .search_l select {
  padding: 11px 0px;
}

.investor_search_dd .search_b1 input {
  padding: 11px 0px;
}

.investor_btnb22 {
  padding: 0px !important;
}

.investor_btnb22 button {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  border: none;
  padding: 10px 16px;
  font-size: 12px;
  margin-left: 5px;
  background: #ffffff;
  border-radius: 2px;
}

.investor_btnb22 i {
  padding: 0px 8px;
  color: #ff8e01;
}

.qlookphbtn .search_l select {
  width: 100%;
  padding: 10px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ced4da;
}

.adeditlocatin {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.booking_newb44 {
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.booking_newb44 .ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.booking_searchn44 {
  width: auto;
  padding: 0px 5px;
}

.booking_searchn44.search_l select {
  padding: 11px 0px;
}

.bookingflbtn {
  padding: 0px !important;
  width: auto;
}

.bookingflbtn button {
  margin: 0px;
}

.rental_mainpay {
  display: flex !important;
  align-items: center !important;
  padding: 5px 15px !important;
}

.rental_mainpay .main_search_dd1 {
  padding: 0px !important;
  justify-content: flex-end;
}

.rentex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  padding: 0px;
  width: auto !important;
}

.rentalpayment .form-group {
  padding: 0px 5px 0px 0px !important;
}

/* .rentalpayment .form-group input {
  padding: 11px 0px !important;
  height: 44px;
} */

.rentalpayment .total_amt {
  width: auto !important;
}

.payment_rlist_tabs .main_vehicle {
  padding: 5px;
}

.payment_rlist_tabs .vehicle_info1 .menuActive {
  border: none !important;
}

.ex_customer {
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.reprental_srchdd77 {
  padding: 0px !important;
}

.reprental_srchdd77 .form-group {
  padding: 0px 5px 0px 0px !important;
}

.reptrental {
  padding: 5px 15px !important;
}

.pendpay_main {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end;
}

.pendpay_main .form-group {
  width: 50% !important;
  position: relative;
}

.pendpay_main .cal_img {
  position: absolute;
  top: 12px;
  right: 12px;
}


.chsearch_tt .search_l {
  width: 90%;
}

.vehic_expiryhh .form-group {
  width: 70% !important;
  position: relative;
}

.vehic_expiryhh .form-group .cal_img {
  position: absolute;
  top: 12px;
  right: 12px;
}

.vehic_expiryhh .eQmQXs {
  right: 0px;
}

.booking_srchmm {
  padding-right: 5px;
  width: 15%;
}

.booking_srchmm input {
  padding: 11px 10px;
}

.closed_search_b22 .search_l {
  padding-right: 5px;
  width: 100%;
}

.closed_search_b33 .search_l {
  padding-right: 5px;
  width: 30%;
}

.closed_search_b33 .search_b1 {
  width: 30%;
}

.closed_search_b33 .form-group {
  width: 30%;
}

.checkout_search9 .search_l22 {
  width: 30%;
}

.checkout_search9 .search_b1 {
  width: 30%;
}

.checkout_search9 .search_main7 {
  width: 30%;
}

.checkout_search91 {
  padding: 6px 15px;
}

.checkout_search91 .search_l22 {
  width: 30%;
}

.checkout_search91 .search_b1 {
  width: 30%;
}

.checkout_search91 .search_main7 {
  width: 30%;
}

.checkout_search91 .form-group {
  width: 30%;
  padding: 0px;
  margin-left: 5px;
  position: relative;
}

.checkout_search9 .form-group {
  padding: 0px;
}

.vehicles_mainsearch .search_l {
  width: 30%;
}

.vehicles_mainsearch .search_b1 {
  width: 30%;
}

.vehiclelist_v777 {
  display: flex !important;
  align-items: center !important;
  padding: 6px 15px;
}

.vehiclelist_v777 .location {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.vhsearch_l {
  width: auto;
}

.vhsearch_l select {
  padding: 11px;
}

.vhsearch_b2 {
  width: auto;
}

.vhsearch_b2 input {
  padding: 11px 0px;
}

.invest_search_l select {
  width: 100%;
  padding: 10px 0px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #003466;
  font-size: 10px;
  outline: none;
}

.checkout_investor .fliter_button2 {
  display: block !important;
}

.checkout_investor {
  padding: 6px 15px;
}

.checkout_investor .search_l22 {
  width: 30%;
}

.checkout_investor .search_b1 {
  width: 30%;
}

.checkout_investor .search_main7 {
  width: 30%;
}

.checkout_investor .form-group {
  width: 30%;
  padding: 0px;
  margin-left: 5px;
}

.checkout_investor select {
  padding: 11px 0px;
}

.checkout_investor input {
  padding: 11px 0px;
}

.company_name .form-group {
  position: relative;
}

.cal_img {
  position: absolute;
  right: 2%;
  top: 50%;
}

.vehicle_docexpiry .make_img {
  position: relative !important;
}

.vehicle_docexpiry .make_img .cal_img {
  position: absolute !important;
  right: 85px !important;
  top: 5px !important;
}

.vehicle_docrcexpiry .make_img {
  position: relative !important;
}

.vehicle_docrcexpiry .make_img .cal_img {
  position: absolute !important;
  right: 85px !important;
  top: 5px !important;
}

.main_cal_img .cal_img {
  position: absolute;
  top: 5px;
  right: 5px;
}

.bkstartrent_dd .search_l {
  width: 50%;
}

.bkstartrent_dd .search_b1 {
  width: 50%;
}

.daytoday_search_dd .search_l {
  width: 50%;
}

.daytoday_search_dd .search_b1 {
  width: 50%;
}

.company_name2 .form-group {
  position: relative;
}

.company_name2 .form-group .cal_img {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
}

.company_name2 .form-group .cal_img img {
  width: 26px;
  height: 26px;
}

.company_name2 {
  width: auto !important;
}

.reprental_srchdd77 {
  justify-content: flex-end;
}

.reprental_srchdd77 .total_amt {
  width: auto !important;
}

.main_search_dd {
  justify-content: flex-end;
}

.investor_report .total_amt {
  width: auto !important;
}

.investor_report {
  padding: 5px 15px !important;
}

.rentalpayment .fliter_button2 {
  width: auto;
  padding: 0px;
  margin-right: 5px;
}

.rentalpayment .fliter_button2 button {
  padding: 8px 16px;
}

.main_search_dd1 {
  padding: 0px !important;
}

.investor_report .cal_img {
  top: 5px !important;
  right: 10px !important;
}

.reprental_srchdd77 .fliter_button2 {
  width: auto;
  padding: 0px;
  margin-right: 5px;
}

.reprental_srchdd77 .fliter_button2 button {
  padding: 8px 16px;
}

.main_search_dd1 .fliter_button2 {
  width: auto !important;
  padding: 0px;
  margin-right: 5px;
}

.company_name23 .form-group {
  padding: 0px 5px 0px 0px;
}

.investor_drop {
  margin-right: 5px;
}

.investor_report .fliter_button2 button {
  padding: 8px 16px;
}

.vehicle_expmain {
  justify-content: space-between;
  padding: 5px 15px !important;
}

.company_name2b55 {
  margin-right: 5px;
}

.vhexarea .company_name2b55 .form-group {
  position: relative;
  padding: 0px;
}

.vhexarea .cal_img {
  position: absolute;
  top: 5px;
  right: 5px;
}

.vhexarea .total_amt {
  width: auto;
}

.vhexarea .fliter_button2 button {
  padding: 8px 16px;
}

.vhinfo_list {
  padding: 5px 15px !important;
}

.rdtPicker {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #003f7d;
  padding: 6px 8px;
  text-align: left;
}

.form-group .rdt input {
  border: none;
  background: #f5f5f5;
  padding: 10px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 0px;
  outline: none;
}

.checkout_search91 .cal_img {
  position: absolute;
  top: 5px;
  right: 5px;
}

.rdtPicker {
  min-width: 220px !important;
}

.vhavailable {
  justify-content: flex-end;
  margin-right: 15px;
}

.booking_srchmain {
  padding: 0px 6px;
  margin-top: 5px;
}



@media (min-width: 320px) and (max-width: 479px) {
  .search_date .eQmQXs {
    min-width: 285px;
  }

  .main_search_dd12new .eQmQXs {
    min-width: 285px;
  }

  .baddpersonal .eQmQXs {
    min-width: 285px;
  }

  .vehicle_geninfo .eQmQXs {
    min-width: 285px;
  }

  .vehicle_document .eQmQXs {
    min-width: 285px;
  }

  .vehicle_docexpiry .eQmQXs {
    min-width: 285px;
  }

  .vehicle_docrcexpiry .eQmQXs {
    min-width: 285px;
    right: auto;
    left: 0px;
  }

  .vehicle_maintanance .eQmQXs {
    min-width: 285px;
  }

  .vehicle_ntservice .eQmQXs {
    min-width: 285px;
    right: auto;
    left: 0px;
  }

  .vehicle_oilservice .eQmQXs {
    min-width: 285px;
  }

  .vehicle_damage .eQmQXs {
    min-width: 285px;
  }

  .vehicle_rentdamage .eQmQXs {
    min-width: 285px;
  }

  .vehicle_sold .eQmQXs {
    min-width: 285px;
  }

  .vehic_expiryhh .eQmQXs {
    min-width: 285px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .basis_price li {
    padding: 6px !important;
  }

  .vehicle_geninfo .rdtPicker {
    right: 0px;
  }

  .vehicle_docrcexpiry .rdtPicker {
    right: 0px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .debit_card_box .col-sm-6 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .search_main7 {
    margin-left: 0px !important;
  }

  .search_date {
    margin-left: 0px;
    width: 100%;
    margin-bottom: 10px;
  }

  .search_daterh {
    margin-left: 0px;
    width: 100%;
  }

  .qlookphbtn {
    display: block !important;
    padding: 20px 10px !important;
  }

  .qlookphbtn .rdt {
    margin-bottom: 5px;
  }

  .qlookbtn {
    margin-left: 0px;
  }

  .main_search_dd12new {
    margin-bottom: 5px !important;
    display: block !important;
  }

  .main_search_dd12new .form-group {
    margin: 0px !important;
  }

  .search_date .form-group {
    padding: 0px;
  }

  .vehicles_mainsearch {
    display: block;
  }

  .vehicle_search_dd {
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }

  .vehicle_filterbtn {
    margin: 0px 0px 5px 0px;
  }

  .booking_search_dd {
    margin-bottom: 10px;
  }

  .booking_searchmain {
    display: block;
  }

  .booking_filterbtn {
    margin: 0px 5px;
  }

  .booking_filterbtn button {
    margin: 0px;
  }

  .booking_startrent {
    display: block;
  }

  .daytoday_search_dd {
    margin-bottom: 10px;
  }

  .daytoday_btn {
    margin: 0px 5px;
  }

  .closed_search_b22 .main_search_dd12 {
    display: block;
  }

  .closed_search_b22 .form-group {
    padding: 0px;
    margin-bottom: 10px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .customer_listb33 {
    display: block;
  }

  .clist_search_dd {
    margin-bottom: 10px;
  }

  .clist_btn {
    margin: 0px 5px;
  }

  .investor_mainbb2 {
    display: block;
  }

  .investor_search_dd {
    margin-bottom: 10px;
  }

  .investor_btnb22 {
    margin: 0px 5px;
  }

  .rental_mainpay {
    display: block !important;
  }

  .rental_mainpay .rentex_customer {
    margin-bottom: 10px;
  }

  .rental_mainpay .main_search_dd1 {
    display: block;
  }

  .rentalpayment .company_name2 {
    width: 100%;
    margin-bottom: 10px;
  }

  /* .rentalpayment .total_amt {
    width: 100% !important;
  } */

  .pendpay_main {
    display: block !important;
  }

  .pendpay_main .form-group {
    width: 100% !important;
    padding: 0px;
  }

  .chsearch_tt .search_l {
    width: 100%;
  }

  .chsearch_tt .form-group {
    padding: 0px;
    margin-bottom: 10px;
  }

  .vehic_expiryhh .form-group {
    width: 100% !important;
    padding: 0px;
  }

  .closed_search_b22 .search_l {
    padding-right: 0px;
  }

  .booking_searchn44 {
    padding: 0px;
  }

  .booking_srchmm {
    margin-bottom: 10px;
  }

  .bookingflbtn {
    margin: 0px;
  }

  .checkout_search9 .search_l22 {
    width: 100%;
  }

  .checkout_search9 .search_b1 {
    width: 100%;
  }

  .checkout_search9 .search_main7 {
    width: 100%;
  }

  .checkout_search91 .search_l22 {
    width: 100%;
  }

  .checkout_search91 .search_b1 {
    width: 100%;
  }

  .checkout_search91 .search_main7 {
    width: 100%;
  }

  .checkout_search91 .form-group {
    width: 100%;
    margin-left: 0px;
  }

  .vehiclelist_v777 {
    display: block !important;
  }

  .search_l22 {
    margin: 0px 0px 10px 0px !important;
  }

  .vhsearch_l {
    margin: 0px 0px 10px 0px !important;
  }

  .vhsearch_b2 {
    margin-bottom: 10px;
  }

  .vehicle_filterbtn button {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .vehiclelist_v777 .location {
    padding: 11px 0px !important;
  }

  .checkout_investor .investor_btnb22 {
    text-align: left;
    margin: 10px 0px 0px 0px;
  }

  .checkout_investor .search_l22 {
    width: 100%;
  }

  .checkout_investor .search_b1 {
    width: 100%;
  }

  .checkout_investor .search_main7 {
    width: 100%;
  }

  .checkout_investor .form-group {
    width: 100%;
    margin-left: 0px;
  }

  .investor_btnb22 button {
    margin-left: 0px;
  }

  .investor_search_dd .search_l {
    width: 100%;
  }

  .closed_search_b33 .search_l {
    width: 100%;
    padding-right: 0px;
  }

  .closed_search_b33 .search_b1 {
    width: 100%;
  }

  .closed_search_b33 .form-group {
    width: 100%;
    padding: 6px 0px;
  }

  .vehicle_search_dd .search_l {
    width: 100% !important;
  }

  .vehicle_search_dd .search_b1 {
    width: 100% !important;
  }

  .rdtPicker {
    min-width: 200px !important;
  }

  .add_personbtn button.submitbutton {
    margin: 0px;
  }

  .rentalpayment .form-group {
    padding: 0px 0px 10px 0px !important;
  }

  .main_search_dd1 {
    display: block;
  }

  .ex_customer.ex_cust {
    margin-bottom: 10px;
  }

  .reprental_srchdd77 {
    display: block !important;
  }

  .reprental_srchdd77 .form-group {
    padding: 0px 0px 10px 0px !important;
  }

  .rentalpayment .fliter_button2 {
    display: block;
    text-align: left;
    margin: 0px;
  }

  .rentalpayment .fliter_button2 button {
    width: 100% !important;
    margin: 0px;
  }

  .main_search_dd1 {
    display: block !important;
  }

  .investor_drop select {
    width: 100%;
    margin-bottom: 10px;
  }

  .company_name23 .form-group {
    padding: 0px;
    margin-bottom: 10px;
  }

  .main_search_dd1 .fliter_button2 {
    display: block;
    text-align: left;
    margin: 0px;
  }

  .main_search_dd1 .fliter_button2 button {
    width: 100% !important;
    margin: 0px;
  }

  .investor_drop {
    margin-right: 0px;
  }

  .vehicle_expmain {
    padding: 5px 15px !important;
  }

  .vhexarea .company_name2b55 .form-group {
    margin-bottom: 10px;
  }

  .company_name2b55 {
    margin-right: 0px;
  }

  .vhavailable .search_main2 {
    margin-left: 0px !important;
  }

  .vehic_expiryhh .form-group .cal_img {
    top: 5px;
    right: 5px;
  }

  .pendpay_main .cal_img {
    top: 5px;
    right: 5px;
  }

  .own_button button {
    margin: 0px 10px 0px 0px;
  }

  .compst_vehicle.chat_right1 {
    padding-left: 0px;
  }

  .edit_details1bb {
    padding: 10px;
  }

  .main_tab_ {
    padding: 20px 8px;
  }

  .stnew_cls {
    display: block !important;
  }

  .stnew_cls .search_l {
    width: 100%;
  }

  .stnew_cls .search_b1 {
    width: 100%;
    margin-bottom: 10px;
  }

  .stnew_cls .bkstartrent_btn {
    margin: 0px;
  }

  .stnew_cls .bkstartrent_btn button {
    width: 100% !important;
  }

  .stnew_cls .bkstartrent_dd {
    padding: 0px;
  }

  .booking_srchmain .booking_srchmm {
    padding-right: 0px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .debit_card_box .col-sm-6 {
    width: 100%;
  }

  .money_image {
    text-align: center;
    margin-top: 20px;
  }

  .main_company .main_row .col-sm-3 {
    width: 50%;
  }

  .mobile_pass {
    margin-bottom: 25px;
  }

  .main_avilable_img {
    display: block !important;
  }

  .checkout_search9 .search_l22 {
    width: 30% !important;
  }

  .checkout_search9 .search_main7 {
    width: 30% !important;
  }

  .checkout_search91 .search_l22 {
    width: 30% !important;
  }

  .checkout_search91 .search_main7 {
    width: 30% !important;
  }

  .main_avilable_img {
    display: block;
  }

  .chsearch_tt .search_l {
    width: 100% !important;
  }

  .main_search_b1 .search_l {
    width: 50% !important;
  }

  .investor_btnb22 {
    width: 30%;
  }

  .qlookphbtn .search_daterh .rdtPicker {
    right: 0px;
    left: auto;
  }

  .total_amt .resp img {
    width: 18px;
    height: 18px;
  }

  .total_amt ul li {
    font-size: 10px;
  }

  .rentalpayment .fliter_button2 {
    width: 35% !important;
  }

  .rentalpayment .fliter_button2 button {
    padding: 10px;
    font-size: 10px;
    width: 100%;
  }

  .rentalpayment .total_amt {
    width: 65% !important;
  }

  .reprental_srchdd77 .fliter_button2 {
    width: 35% !important;
  }

  .reprental_srchdd77 .fliter_button2 button {
    padding: 10px;
    font-size: 10px;
    width: 100%;
  }

  .reprental_srchdd77 .total_amt {
    width: 65% !important;
  }

  /* .investor_drop {
    width: 30%;
  } */

  .investor_drop select {
    font-size: 10px !important;
    padding: 10px 5px !important;
  }

  .investor_report .total_amt {
    width: 65% !important;
  }

  .investor_report .fliter_button2 button {
    font-size: 10px !important;
    padding: 2px 8px;
  }

  .rdtPicker {
    right: 0px;
  }

  .company_nameddtask .rdtPicker {
    left: 0px;
    right: auto;
  }



}

@media (min-width: 992px) and (max-width: 1199px) {
  .main_avilable_img {
    display: block !important;
  }

  .rdtPicker {
    min-width: 200px !important;
  }

  .investor_report .main_search_dd1 .fliter_button2 {
    width: 40% !important;
    display: block;
    text-align: left;
  }

  .main_search_dd1 .fliter_button2 button {
    width: 100%;
  }

  .investor_report .total_amt {
    width: 60% !important;
  }
}
.main_padding {
    padding: 0px 12px;
}

.gen-plnnrdiv {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.general-main {
    color: #003466;
    background-color: #C7E2F9;
}

.general-plannr {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.check_show {
    display: flex;
    align-items: center;
    padding: 0px 20px
}

.general-chckbox {
    display: flex;
    padding: 6px 4px;
    align-items: center;
}

.gen-check {
    display: flex;
    align-items: center;

}

.inspect-inpt {
    width: 50%;
}

.inspect-inpt input {
    width: 100%;
}

.inspect-inpt input#Advance {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    background: #F5F5F5;
    border-radius: 2px;
    min-height: 37px;
    text-align: left;
    border: none;
    outline: none;
}


.inspect {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    padding: 0px 10px;
}

.show {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    padding-left: 5px;

}

.advance {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    width: 50%;
    padding-left: 10px;
}

.general-form {
    display: flex;
}

.general-mainpckdrp {
    color: #003466;
    background-color: #C7E2F9;
}

.general-pickdrop {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.general-pickup {
    display: flex;
    padding: 14px 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.slectpickup {
    width: 100%;
    min-height: 37px;
    margin: 12px 0px;
    border: none;
    background: #F5F5F5;
    outline: none;
}


label.pickup {
    padding: 0px 6px;
}

.drop-location {
    padding: 0px 5px;
}

.tab_input.flt {
    padding: 0px 5px;
}

.slectdrop {
    width: 100%;
    min-height: 37px;
    outline: none;
    margin: 12px 0px;
    border: none;
    background: #F5F5F5;
}

.gen-pckdrpdiv {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.chng-location {
    display: flex;
}

.chng-loc {
    padding: 0px 6px;
}

.gen-srvceint {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-service {
    margin: 6px 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
}

.general-km {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 34px 10px;
    color: #003466;
}

.months {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
    padding: 34px 0px;
}

.gen-or {
    text-align: center;
    padding-top: 34px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003466;
}

.gen-km {
    display: flex;
}

.Months-inpt input#Months {
    background: #F5F5F5;
    border-radius: 2px;
    min-height: 37px;
    text-align: center;
    margin: 22px 10px;
    border: none;
}

.genkm-inpt input#km {
    background: #F5F5F5;
    border-radius: 2px;
    min-height: 37px;
    text-align: center;
    margin: 22px 10px;
    border: none;
}

.general-interval {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.gen-kmmonth {
    display: flex;
}

.fuel-chrg {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.gen-mainregnl {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-reminders {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-fuelchrgmain {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
}

.genmain-fuelchrg {
    margin: 6px 0px;
}

.gen-fuelmangmnt {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-calf {
    padding: 24px 10px;
}

.gen-calfuel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.gen-Apply {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.fuel-standchrg {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.gen-fuelstandchrg input#fuelstand {
    padding: 6px 0px;
    text-align: left;
    width: 82%;
    min-height: 34px;
    border: none;
    background: #F5F5F5;
    outline: none;
}

.traffic-fineagrmnt {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 25px 10px 0px 10px;
}

.gen-nrmlchrg {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.fuel-chrgselct {
    padding: 0px 0px;
    text-align: center;
    width: 82%;
    min-height: 40px;
    border: none;
    background: #F5F5F5;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.weekdays {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    text-align: center;
    padding: 31px 0px;
}

.weekdays-inpt {
    padding: 24px 10px;
}

.gen-fuel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.fuelnrml-stndrd {
    display: flex;
    margin: 0px 0px 27px 10px;
}

.gen-remndr {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-rmdrpln {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #003466;
    padding: 10px 10px;
}

.remndr-weeks {
    display: flex;
}

.gen-mainregnl {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-reginl {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-reginlsettng {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.gen-mainDstnce {
    display: flex;
}

.dstnce-Strng {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.dstnce-Strngselct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 50%;
    min-height: 37px;
    outline: none;
    margin: 12px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.curency-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 82%;
    min-height: 37px;
    outline: none;
    margin: 12px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.conversion-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 82%;
    min-height: 37px;
    outline: none;
    margin: 12px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.coverstion-inpt input#conversion {
    padding: 6px 5px;
    outline: none;
    width: 82%;
    min-height: 38px;
    margin: 11px 10px;
    background: #F5F5F5;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.month-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 42%;
    min-height: 37px;
    outline: none;
    margin: 16px 10px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
}

.mainvhcl-avalblty {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.gen-avalblty {
    color: #003466;
    background-color: #C7E2F9;
}

.gen-Curency {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.genvhcl-avalblty {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 10px;
}

.genmain-trfficfines {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 63, 125, 0.1);
    margin: 6px 0px;
}

.after-check {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.after-check label {
    padding-left: 5px;
}

.rent-end {
    display: flex;
    align-items: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 10px 10px;
}

.rent-end label {
    padding-left: 5px;
}

.fines-selct {
    background: #F5F5F5;
    border-radius: 2px;
    width: 42%;
    min-height: 37px;
    text-align: center;
    margin: 12px 10px;
    border: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.req_field1 {
    font-size: 10px;
    padding: 3px 10px;
    color: #b42b2b;
}

@media (min-width: 320px) and (max-width: 479px) {
    .check_show {
        padding: 0px;
    }

    .inspect {
        padding: 0px 5px;
    }
}

@media (min-width: 640px) and (max-width: 767px) {
    .chat_right1 .main_padding .col-sm-6 {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .chat_right1 .main_padding .col-sm-6 {
        width: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .chat_right1 .main_padding .col-sm-6 {
        width: 100%;
    }
}
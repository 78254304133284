input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.red {
  color: red;
}

.green {
  color: green;
}

.viewdata {
  padding: 6px 16px;
  background: #E6F2FF;
  border: 1px solid #9ECFFF;
  border-radius: 3px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #003F7D;
}

.button_style1 {
  padding: 6px 16px;
  background: #FFC4C4;
  border-radius: 3px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style {
  padding: 6px 16px;
  background: #D0FFC5;
  border-radius: 3px;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 18px;
}


button.per_info {
  background: none;
  border: none;
  color: #003F7D;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
}

.button_style_onrent {
  padding: 6px 16px;
  background: #FF8E01;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style_completed {
  padding: 6px 16px;
  background: #09f709;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style_completed1 {
  padding: 6px 33px;
  background: #D0FFC5;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #00610A;
}
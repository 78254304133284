.total_amt {
    background-color: #123f7d;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    width: 60%;
    border: 1px solid #FF8E01;
    display: flex;
    align-items: center;

}

.ex_cust {
    width: 22%;
}

.main_search_dd1 {
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.company_name2 {
    width: 50%;
    padding: 0px !important;
}

.total_amt ul li {
    list-style: none;
    display: inline-block;

}

.main_search_b22 {
    margin-left: 0px !important;
    padding: 5px 0px;
}

.resp img {
    margin-left: 0px !important;

}

.resp {
    border-right: 1px solid #FF8E01;
    padding: 10px;
}

.total_amt ul {
    padding: 10px;
    margin: 0px;
}

.colon_span {
    padding: 0px 10px;
}

.main_vehicle_info1 {
    padding: 0px !important;
}

.vehicle_info1 li {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    padding: 14px 12px !important;
}

.vehicle_info1 li:hover {
    background-color: white;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #003466;
}

@media (min-width: 320px) and (max-width: 479px) {
    .company_name2 {
        width: 100%;
    }
}
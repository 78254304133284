/* header icon start */
.menu_h {
  padding: 0px;
  margin: 0px;
}

.menu_h li {
  float: left;
  list-style-type: none;
  width: 8.33%;
  border-right: 1px solid #f7f7f7;
  border-left: 1px solid #f7f7f7;
  border-bottom: 2px solid #f7f7f7;
  text-align: center;
}

.menu_h li a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  color: #003f7d;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  float: left;
  width: 100%;
  padding: 10px 0px;
}
.menu_h li a img {
  margin-bottom: 5px;
}
.menu_h li:hover {
  border-bottom: 3px solid #003f7d;
  background: #f7f7f7;
}

.menuActive {
  border-bottom: 3px solid #003f7d !important;
  background: #f7f7f7 !important;
}

/* header icon end */


@media (max-width: 767px) {
  .menu_h li a {
    font-size: 10px;
  }
  /* .chat_left {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    margin: 0px;
    width: 0px !important;
    opacity: 0;
    visibility: hidden;
} */
}
@media (min-width: 768px) and (max-width: 991px) {
  .menu_h li {
    width: 10%;
  }
  .menu_h li a {
    font-size: 7px;
  }
  .menu_h li a img {
    margin-bottom: 2px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .menu_h li {
    width: 10%;
  }
  .menu_h li a {
    font-size: 9px;
  }
}
@media (min-width: 1200px) and (max-width: 1365px) {
  .menu_h li {
    width: 10%;
  }
}
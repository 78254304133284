.maintenance.flt {
    background: #c7e2f9;
    color: #003466 !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    padding: 13px;
    font-size: 16px;
}
.sub_details ul li{
    list-style: none;
    padding: 6px;
    font-size: 10px;
 color: #003466 !important;
    font-family: "Montserrat",sans-serif;
}
.sub_details li:nth-child(odd){
    float: left;
    width: 40%;
}
.sub_details li:nth-child(even){
    float: left;
    width: 60%;
}
.sub_details ul li span{float: right; padding: 0px 6px;}
.sub_details b span{
    text-align: center;
}
.sub_details1{
    text-align: center;
    padding: 100px;
}
.mobile_pass {
  background-image: url(../../../assets/images/new-agreement-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main_book {
  padding: 20px 0px;
}
.numdays {
    color: #f94155;
}
.bookenddate .labelName {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bookenddate .labelName .numdays {
    margin-top: -15px;
}
.main_booking button {
  border: none;
  padding: 12px 2px;
  background: #003f7d;
  border-radius: 2px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  width: 50%;
  text-align: center;
  border: 1px solid transparent;
  font-weight: 500;
}

.main_booking button:hover {
  background: #ffffff;
  color: #003f7d;
  border: 1px solid #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
}

.exp_date {
  padding: 8px;
  border: 2px solid;
  background-color: #e7fffd;
}

.delete_btn1 {
  opacity: 0.5;
  pointer-events: none;
  border: none;
  padding: 8px 20px;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #ff8e01;
}

.button_style {
  padding: 6px 16px;
  background: #d0ffc5;
  border-radius: 3px;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 18px;
}

.viewdata {
  padding: 6px 16px;
  background: #e6f2ff;
  border: 1px solid #9ecfff;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #003f7d;
}

.button_style1 {
  padding: 6px 16px;
  background: #ffc4c4;
  border-radius: 3px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.Main_img_button {
  padding: 10px 10px;
  text-align: center;
}

.agedata span {
  background: #e3f6ff;
  border: 1px solid #003f7d;
  border-radius: 2px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #003f7d;
}

.main_booking {
  margin: 25px 0px;
}

.Main_img_button img {
  padding: 20px 0px;
}

/* booking start */

.booking_vehicle {
  text-align: left;
  padding-left: 12px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.Search_but {
  padding: 22px 0px;
}

.Search_but button {
  padding: 10px 0px;
  width: 100%;
  background: #003f7d;
  color: #ffffff;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border-radius: 2px;
}



/* rental rates start */
.main_car_details {
  padding: 30px 15px;
}

.car_details {
  text-align: left;
  background-color: #c7e2f9;
  padding: 10px 6px;
  border-bottom: 1px solid #003f7d;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.details_car {
  background: #f5f5f5;
  padding: 30px 15px;
  text-align: left;
  display: flex;
}

.details_car img {
  background-color: #ffffff;
  padding: 12px 0px;
}

ul.make_details {
  margin: 0px;
  padding: 0px;
  float: left;
}

.make_details li {
  float: left;
  list-style-type: none;
  width: 35%;
  text-align: left;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 16px;
}

.make_details li span {
  float: right;
}

.upload_text {
  text-align: left;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 0px;
}

/* rental rates end */

/* popup start */
.modal1 {
  opacity: 1;
  position: fixed;
  z-index: 999;
  display: block;
}

.book_st {
  display: flex;
  text-align: center;
  padding: 0px 45px;
}

.pop_car.flt {
  display: flex;
  justify-content: center;
}

/* popup end */
.otp_back {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  width: 50%;
  border: none;
  height: 40px;
  margin: 0px 10px;
  border-radius: 3px;
}

.otp_button {
  display: flex;
  align-items: center;
  margin: 40px 0px;
}

.otp_back:hover {
  background: #ffffff;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003466;
}

.otp_code.flt {
  display: flex;
  justify-content: space-between;
}

.otp_code input {
  margin: 0px 5px;
  text-align: center;
  color: #003466;
}

.verify_msg.flt {
  margin: 12px 0px;
  background: #cae5ff;
  padding: 10px 10px;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #003f7d;
}

.success_img img {
  margin: 0px 5px;
}

.verify_msg1.flt {
  margin: 12px 0px;
  background: #fff8f8;
  padding: 10px 10px;
  color: #f00000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #f33e3e;
}

.resend_otp.flt {
  padding: 10px 12px;
}

.re_button {
  width: 100%;
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: none;
  border-radius: 2px;
  padding: 15px 0px;
}

.rent_walk {
  background: #003f7d;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 15px 10px;
}

.ex_customer.flt {
  display: flex;
  align-items: center;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  padding: 15px 10px;
}

.main_search_b2 {
  display: flex;
  background: #9dd2ff;
  align-items: center;
}

.main_search_b2 img {
  /* width: 30px;
  height: 22px; */
  display: flex;
  align-items: center;
  margin-left: 15px;
}



.react-datetime-picker__wrapper {
  border: none !important;
}

button.react-datetime-picker__clear-button.react-datetime-picker__button {
  display: none;
}

button.react-datetime-picker__calendar-button.react-datetime-picker__button {
  display: none;
}

.invalid-feedback {
  display: block !important;
  font-size: 10px !important;
  padding: 10px;
}

/* media query */
@media (max-width: 767px) {
  .make_details li {
    padding: 5px 6px;
    width: 40%;
  }
}

.is-invalid {
  border: 1px solid red !important;
}

.delete_btn123 {
  pointer-events: none;
  opacity: 0.5;
  width: 20%;
  border: none;
  padding: 10px 0px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: #003f7d;
}


.dateform {
  background: #e3f6ff !important;
  border: 1px solid #003f7d !important;
  border-radius: 2px !important;
  padding: 10px 20px !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #003f7d !important;
}

.insuranceinfo_show {
  pointer-events: none;
  opacity: 0.5;
}

.insuranceinfo_show1 {
  pointer-events: visible !important;
  opacity: 1 !important;
}

/* popup payment successfull start */

.payment_success.flt {
  text-align: center;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20.1951px;
  line-height: 25px;
}

.modal {
  background: #000000ba;
}

.vehicle_due.flt {
  padding: 15px 75px;
}

.company_namee textarea {
  border: none;
  background: #f5f5f5;
  padding: 2px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  border-radius: 2px;
  height: 70px;
}

.resend_otp1.flt {
  padding: 10px 8px;
}

.re_button1 {
  margin: 5px 0px;
  width: 100%;
  background: #ffff;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003f7d;
  border-radius: 2px;
  padding: 15px 0px;
}

.re_button2 {
  pointer-events: none;
  opacity: 0.5;
}

.re_button1:hover {
  background-color: #003f7d;
  color: #ffff;
}

.close_button {
  border: none;
  padding: 2px 15px;
  background: none;
}

/* popup payment successfull end */

.dMmUVV:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.button_style1 {
  color: #a30000;
  padding: 10px 10px;
}

.wfIwh {
  width: 100%;
  padding: 10px !important;
  background: white;
  border: none;
  border-radius: 1px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: #003466;
}

/* car Exchange start */

.payment_success1 {
  text-align: center;
  color: #003466;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20.1951px;
  line-height: 25px;
}

.main_old_vehicle.flt {
  padding-top: 20px;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: #316191;
}

.main_old_vehicle span {
  color: #ffa412;
}

.resend_otp2.flt {
  text-align: center;
  padding-top: 30px;
}

.re_button12 {
  margin: 5px 0px;
  width: 90%;
  background: #ffff;
  color: #003f7d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #003f7d;
  border-radius: 2px;
  padding: 15px 0px;
  text-align: center;
}

.re_button12:hover {
  background-color: #003f7d;
  color: #ffff;
}


.button_style_onrent {
  padding: 6px 22px;
  background: #FF8E01;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
}

.button_style_completed {
  padding: 6px 16px;
  background: #D0FFC5;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #00610A;
}

.button_style_pending {
  padding: 6px 22px;
  background: #FFC4C4;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #A30000;
}

.button_style_completed1 {
  padding: 6px 33px;
  background: #D0FFC5;
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  align-items: center;
  color: #00610A;
}

/* car Exchange end */
.incomplete {
  margin-left: 7px;
}

@media (min-width: 320px) and (max-width: 479px) {
  .main_company .main_row .col-sm-3 {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .main_company .main_row .col-sm-3 {
    width: 50%;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .main_company .main_row .col-sm-3 {
    width: 50%;
  }

  .main_company .main_row .col-sm-4 {
    width: 50%;
  }

  .main_car_details .col-sm-4 {
    width: 100%;
  }

  .main_grand_total .row {
    width: 100%;
    float: left;
  }

  .main_grand_total .row .col-sm-6 {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .main_search_b1 {
    display: block;
  }

  .main_search_dd12 {
    width: 100%;
    padding: 0px;
  }

  .search_l {
    width: 100%;
    margin: 10px 0px;
  }

  .search_b1 {
    width: 100%;
  }

  .main_search_b1 {
    display: block;
    padding: 0px 20px 10px 20px !important;
  }

  .main_search_dd12 .form-group {
    padding: 0px;
  }

  .main_search_dd12 .form-group:nth-child(2) {
    margin-left: 10px;
  }

  .main_search_dd12 .form-group:nth-child(1) {
    margin-right: 10px;
  }

  .main_ownbutton {
    display: block !important;
  }

  .main_ownbutton .own_button {
    padding: 10px;
  }
  .own_button button {
    width: 100%;
    margin-bottom: 10px;
}
.money_image {
  margin-top: 20px;
}
.money_image img {
  width: 100%;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .main_location .location {
    width: 33%;
  }

  .main_search_b1 .search_l {
    width: 50%;
  }

  .main_search_dd12 {
    padding: 0px;
  }

  .main_search_dd12 .form-group {
    padding: 6px 2px;
  }

  .main_car_details .col-sm-4 {
    width: 100%;
  }

  .delete_btn12 {
    width: 25%;
  }

  .main_company .main_row .col-sm-4 {
    width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main_location .location {
    width: 40%;
  }

  .main_car_details .col-sm-4 {
    width: 60%;
  }

  .main_company .main_row .col-sm-4 {
    width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .main_location .location {
    width: 50%;
  }
}
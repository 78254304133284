

/* payment start */
.form-group1 {
font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
    text-align: left;
  background: #C7E2F9;
border: 1px solid #E0F0FF;
}
.make_img1 {
    display: flex;
    align-items: center;
}

.make_img1 i{
    padding: 15px 15px;
    background:#ffffff;
    margin-right: 2px;

}


.company_name1 input {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}


.form-group4 {
font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #003f7d;
    padding: 6px 8px;
    text-align: left;
 

}
.make_img4 {
    display: flex;
    align-items: center;
}

.make_img4 i {
   padding: 14px 15px;
    background:#ffffff;
    margin-right: 2px;
        border-radius: 2px;
}

.company_name4 input {
    border: none;
  background-color: #ffffff;
    padding: 12px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.pay_mode.flt {
    display: flex;
    align-items: center;
    background: #C7E2F9;
border: 1px solid #E0F0FF;
}

.main_helmet_padd.flt {
    padding: 0px 8px;
}

.helmet_change.flt {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #003F7D;
    padding: 0px 8px;
}

.total_rental.flt {
    padding: 15px 0px;
}

.top_helmet_pad.flt {
    padding: 10px 0px;
}

.credit_card {
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;

}
.credit_card img {
  padding: 0px 5px;

}

.credit_card_type {
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}
.make_im {
  display: flex;
}

.make_im img{
 padding: 10px;
 background-color: #ffffff;
}
.company_name8 input {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.company_name8 select {
    border: none;
  background-color: #ffffff;
    padding: 10px 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    border-radius: 2px;
    outline: none;
}

.main_pay_debit.flt {
    background: #C7E2F9;
    border: 1px solid #E0F0FF;
        padding-bottom: 60px;

}

.cvv_number {
    display: flex;
    padding: 7px 0px;
    
}
.cvv_date input{
    width: 100%;
  padding: 12px 5px;
    border: none;
  outline: none;
  border-radius: 2px;
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}

.ex_date input {
  width: 100%;
 padding: 12px 5px;
  border: none;
  outline: none;
  color: #003F7D;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;

}
label.cvv_num {
    color: #003F7D;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 6px 3px;
}
.trans_img {
    font-size: 12px;
    color: red;
}
.cvv_date {
    padding: 0px 5px;
}
.ex_date {
    padding: 0px 5px;
}

.main_atm_card.flt {
    padding: 15px 2px;
}

.info_circle.flt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info_circle i {
  font-size: 13px;
}


.info_pop {
    position: relative;
        display: flex;
    align-items: center;
}

.info_pop i {
    cursor: pointer;
}

.hover_me {
    position: absolute;
    left: -33px;
    top: -38px;
    background-color: #003F7D;
    padding: 4px 10px;
    border-radius: 30px;
    box-shadow: 0px 0px 6px rgb(188 188 188);
    display: none;
    color: #ffff;
}

.hover_me::before {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -13px;
    font-size: 25px;
    color: #003F7D;
}

.info_pop:hover .hover_me {
  display: block;
}

.edit_details {
    text-align: right;
    margin: 0px 14px;
}

.rr_bross_contant {
    background-color: #C7E2F9;
    display: flex;
    text-align: center;

}

.rr_bross {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #003466;
    padding: 16px 14px;
}

.right_side_owner {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #003466;
    padding: 2px 20px;
}

.owner_nametag {
    color: #003466;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 27px;
}

.details_owner1 {
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #003F7D;
}

.details {
    padding: 34px 4px;
}

.ed_location {
    border: none;
    padding: 8px 20px;
    color: #003f7d;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    background: #ff8e01;
    margin: 0px 5px;
}





@media (max-width: 767px) {}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (min-width: 320px) and (max-width: 479px) {
    .chat_right1 .fliter_button button {
        width: 100%;
    }
}